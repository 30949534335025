import { useTranslations } from 'next-intl';
import { FC, useCallback, useMemo } from 'react';
import cls from './packagesBlock.module.css';
import { Package, Product } from '@/entities/Package';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { Button } from '@/shared/ui/Buttons';
import { OldOrNewPrice } from '@/shared/ui/OldOrNewPrice';
import { Flex, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

interface IPackageAside {
  redirectToPayment: (value: Product) => void;
  selectedPackageLocal?: Package;
}

const PackageAside: FC<IPackageAside> = props => {
  const { redirectToPayment, selectedPackageLocal } = props;
  const t = useTranslations();
  const { isMobile, isDesktop } = useDetectDevice();

  const direction = useMemo(() => (isMobile ? 'column' : 'row'), [isMobile]);
  const align = useMemo(() => (isMobile ? 'center' : 'start'), [isMobile]);
  const justify = useMemo(() => (isDesktop ? 'start' : 'center'), [isDesktop]);

  const classNameWrapper = (index: number) =>
    classNames(cls['item-packages'], { [cls['item-fund']]: index > 0 }, []);

  const packageTitle = useCallback(
    (isTrial: boolean) =>
      t(isTrial ? 'For new users only' : 'Regular subscription'),
    [t],
  );
  const packageSubtitle = useCallback(
    (isTrial: boolean) =>
      `• ${t(
        isTrial
          ? 'Current offer is valid only for the first time purchase'
          : 'Subscription for users that previously got the Trial one',
      )}`,
    [t],
  );
  const packageSecondSubtitle = useCallback(
    (price: string) =>
      `• ${t('At the end of your subscription, you will be automatically charged')} $${price}`,
    [t],
  );

  const sizeNumber = useMemo(() => {
    if (isMobile) return 's';
    if (isDesktop) return 'l';
    return 'm';
  }, [isDesktop, isMobile]);

  return (
    <Flex direction={direction} align={align} justify={justify} gap='x-l'>
      {selectedPackageLocal?.products.map((item, index) => (
        <VStack
          key={item.id}
          justify='between'
          gap='2-x-l'
          className={classNameWrapper(index)}
        >
          <VStack gap='x-l'>
            <OldOrNewPrice
              newPrice={item.price || ''}
              oldPrice={item.oldPrice || ''}
              period={item.duration}
              size={sizeNumber}
            />
            <VStack gap='m'>
              <Text variant='body-l'>{packageTitle(item.isTrial)}</Text>
              <VStack gap='s'>
                <Text variant='body-s'>{packageSubtitle(item.isTrial)}</Text>
                {!!item.isTrial && !!item?.oldPrice && (
                  <Text variant='body-s'>
                    {packageSecondSubtitle(item.oldPrice)}
                  </Text>
                )}
              </VStack>
            </VStack>
          </VStack>
          <Button
            text={t('Subscribe2')}
            onClick={() => redirectToPayment(item)}
          />
        </VStack>
      ))}
    </Flex>
  );
};

export default PackageAside;
