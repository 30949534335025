import EnReview1AvifDesktop from '@/shared/assets/images/Desktop/Reviews/EnVersion/Review1_Desktop.avif';
import EnReview1PngDesktop from '@/shared/assets/images/Desktop/Reviews/EnVersion/Review1_Desktop.png';
import EnReview1WebpDesktop from '@/shared/assets/images/Desktop/Reviews/EnVersion/Review1_Desktop.webp';
import EnReview2AvifDesktop from '@/shared/assets/images/Desktop/Reviews/EnVersion/Review2_Desktop.avif';
import EnReview2PngDesktop from '@/shared/assets/images/Desktop/Reviews/EnVersion/Review2_Desktop.png';
import EnReview2WebpDesktop from '@/shared/assets/images/Desktop/Reviews/EnVersion/Review2_Desktop.webp';
import EnReview3AvifDesktop from '@/shared/assets/images/Desktop/Reviews/EnVersion/Review3_Desktop.avif';
import EnReview3PngDesktop from '@/shared/assets/images/Desktop/Reviews/EnVersion/Review3_Desktop.png';
import EnReview3WebpDesktop from '@/shared/assets/images/Desktop/Reviews/EnVersion/Review3_Desktop.webp';
import EnReview4AvifDesktop from '@/shared/assets/images/Desktop/Reviews/EnVersion/Review4_Desktop.avif';
import EnReview4PngDesktop from '@/shared/assets/images/Desktop/Reviews/EnVersion/Review4_Desktop.png';
import EnReview4WebpDesktop from '@/shared/assets/images/Desktop/Reviews/EnVersion/Review4_Desktop.webp';
import EnReview5AvifDesktop from '@/shared/assets/images/Desktop/Reviews/EnVersion/Review5_Desktop.avif';
import EnReview5PngDesktop from '@/shared/assets/images/Desktop/Reviews/EnVersion/Review5_Desktop.png';
import EnReview5WebpDesktop from '@/shared/assets/images/Desktop/Reviews/EnVersion/Review5_Desktop.webp';
import EnReview6AvifDesktop from '@/shared/assets/images/Desktop/Reviews/EnVersion/Review6_Desktop.avif';
import EnReview6PngDesktop from '@/shared/assets/images/Desktop/Reviews/EnVersion/Review6_Desktop.png';
import EnReview6WebpDesktop from '@/shared/assets/images/Desktop/Reviews/EnVersion/Review6_Desktop.webp';
import EnReview7AvifDesktop from '@/shared/assets/images/Desktop/Reviews/EnVersion/Review7_Desktop.avif';
import EnReview7PngDesktop from '@/shared/assets/images/Desktop/Reviews/EnVersion/Review7_Desktop.png';
import EnReview7WebpDesktop from '@/shared/assets/images/Desktop/Reviews/EnVersion/Review7_Desktop.webp';
import Review1AvifDesktop from '@/shared/assets/images/Desktop/Reviews/ReviewAvif/Review1_426x304.avif';
import Review2AvifDesktop from '@/shared/assets/images/Desktop/Reviews/ReviewAvif/Review2_426x304.avif';
import Review3AvifDesktop from '@/shared/assets/images/Desktop/Reviews/ReviewAvif/Review3_426x304.avif';
import Review4AvifDesktop from '@/shared/assets/images/Desktop/Reviews/ReviewAvif/Review4_426x304.avif';
import Review5AvifDesktop from '@/shared/assets/images/Desktop/Reviews/ReviewAvif/Review5_426x304.avif';
import Review6AvifDesktop from '@/shared/assets/images/Desktop/Reviews/ReviewAvif/Review6_426x304.avif';
import Review7AvifDesktop from '@/shared/assets/images/Desktop/Reviews/ReviewAvif/Review7_426x304.avif';
import Review1PngDesktop from '@/shared/assets/images/Desktop/Reviews/ReviewPng/Review1_426x304.png';
import Review2PngDesktop from '@/shared/assets/images/Desktop/Reviews/ReviewPng/Review2_426x304.png';
import Review3PngDesktop from '@/shared/assets/images/Desktop/Reviews/ReviewPng/Review3_426x304.png';
import Review4PngDesktop from '@/shared/assets/images/Desktop/Reviews/ReviewPng/Review4_426x304.png';
import Review5PngDesktop from '@/shared/assets/images/Desktop/Reviews/ReviewPng/Review5_426x304.png';
import Review6PngDesktop from '@/shared/assets/images/Desktop/Reviews/ReviewPng/Review6_426x304.png';
import Review7PngDesktop from '@/shared/assets/images/Desktop/Reviews/ReviewPng/Review7_426x304.png';
import Review1WebpDesktop from '@/shared/assets/images/Desktop/Reviews/ReviewWebp/Review1_426x304.webp';
import Review2WebpDesktop from '@/shared/assets/images/Desktop/Reviews/ReviewWebp/Review2_426x304.webp';
import Review3WebpDesktop from '@/shared/assets/images/Desktop/Reviews/ReviewWebp/Review3_426x304.webp';
import Review4WebpDesktop from '@/shared/assets/images/Desktop/Reviews/ReviewWebp/Review4_426x304.webp';
import Review5WebpDesktop from '@/shared/assets/images/Desktop/Reviews/ReviewWebp/Review5_426x304.webp';
import Review6WebpDesktop from '@/shared/assets/images/Desktop/Reviews/ReviewWebp/Review6_426x304.webp';
import Review7WebpDesktop from '@/shared/assets/images/Desktop/Reviews/ReviewWebp/Review7_426x304.webp';
import EnReview1AvifMobile from '@/shared/assets/images/Mobile/Reviews/EnVersion/Review1_Mobile.avif';
import EnReview1PngMobile from '@/shared/assets/images/Mobile/Reviews/EnVersion/Review1_Mobile.png';
import EnReview1WebpMobile from '@/shared/assets/images/Mobile/Reviews/EnVersion/Review1_Mobile.webp';
import EnReview2AvifMobile from '@/shared/assets/images/Mobile/Reviews/EnVersion/Review2_Mobile.avif';
import EnReview2PngMobile from '@/shared/assets/images/Mobile/Reviews/EnVersion/Review2_Mobile.png';
import EnReview2WebpMobile from '@/shared/assets/images/Mobile/Reviews/EnVersion/Review2_Mobile.webp';
import EnReview3AvifMobile from '@/shared/assets/images/Mobile/Reviews/EnVersion/Review3_Mobile.avif';
import EnReview3PngMobile from '@/shared/assets/images/Mobile/Reviews/EnVersion/Review3_Mobile.png';
import EnReview3WebpMobile from '@/shared/assets/images/Mobile/Reviews/EnVersion/Review3_Mobile.webp';
import EnReview4AvifMobile from '@/shared/assets/images/Mobile/Reviews/EnVersion/Review4_Mobile.avif';
import EnReview4PngMobile from '@/shared/assets/images/Mobile/Reviews/EnVersion/Review4_Mobile.png';
import EnReview4WebpMobile from '@/shared/assets/images/Mobile/Reviews/EnVersion/Review4_Mobile.webp';
import EnReview5AvifMobile from '@/shared/assets/images/Mobile/Reviews/EnVersion/Review5_Mobile.avif';
import EnReview5PngMobile from '@/shared/assets/images/Mobile/Reviews/EnVersion/Review5_Mobile.png';
import EnReview5WebpMobile from '@/shared/assets/images/Mobile/Reviews/EnVersion/Review5_Mobile.webp';
import EnReview6AvifMobile from '@/shared/assets/images/Mobile/Reviews/EnVersion/Review6_Mobile.avif';
import EnReview6PngMobile from '@/shared/assets/images/Mobile/Reviews/EnVersion/Review6_Mobile.png';
import EnReview6WebpMobile from '@/shared/assets/images/Mobile/Reviews/EnVersion/Review6_Mobile.webp';
import EnReview7AvifMobile from '@/shared/assets/images/Mobile/Reviews/EnVersion/Review7_Mobile.avif';
import EnReview7PngMobile from '@/shared/assets/images/Mobile/Reviews/EnVersion/Review7_Mobile.png';
import EnReview7WebpMobile from '@/shared/assets/images/Mobile/Reviews/EnVersion/Review7_Mobile.webp';
import Review1AvifMobile from '@/shared/assets/images/Mobile/Reviews/ReviewsAvif/Review1_336x348.avif';
import Review2AvifMobile from '@/shared/assets/images/Mobile/Reviews/ReviewsAvif/Review2_336x348.avif';
import Review3AvifMobile from '@/shared/assets/images/Mobile/Reviews/ReviewsAvif/Review3_336x348.avif';
import Review4AvifMobile from '@/shared/assets/images/Mobile/Reviews/ReviewsAvif/Review4_336x348.avif';
import Review5AvifMobile from '@/shared/assets/images/Mobile/Reviews/ReviewsAvif/Review5_336x348.avif';
import Review6AvifMobile from '@/shared/assets/images/Mobile/Reviews/ReviewsAvif/Review6_336x348.avif';
import Review7AvifMobile from '@/shared/assets/images/Mobile/Reviews/ReviewsAvif/Review7_336x348.avif';
import Review1PngMobile from '@/shared/assets/images/Mobile/Reviews/ReviewsPng/Review1_336x348.png';
import Review2PngMobile from '@/shared/assets/images/Mobile/Reviews/ReviewsPng/Review2_336x348.png';
import Review3PngMobile from '@/shared/assets/images/Mobile/Reviews/ReviewsPng/Review3_336x348.png';
import Review4PngMobile from '@/shared/assets/images/Mobile/Reviews/ReviewsPng/Review4_336x348.png';
import Review5PngMobile from '@/shared/assets/images/Mobile/Reviews/ReviewsPng/Review5_336x348.png';
import Review6PngMobile from '@/shared/assets/images/Mobile/Reviews/ReviewsPng/Review6_336x348.png';
import Review7PngMobile from '@/shared/assets/images/Mobile/Reviews/ReviewsPng/Review7_336x348.png';
import Review1WebpMobile from '@/shared/assets/images/Mobile/Reviews/ReviewsWebp/Review1_336x348.webp';
import Review2WebpMobile from '@/shared/assets/images/Mobile/Reviews/ReviewsWebp/Review2_336x348.webp';
import Review3WebpMobile from '@/shared/assets/images/Mobile/Reviews/ReviewsWebp/Review3_336x348.webp';
import Review4WebpMobile from '@/shared/assets/images/Mobile/Reviews/ReviewsWebp/Review4_336x348.webp';
import Review5WebpMobile from '@/shared/assets/images/Mobile/Reviews/ReviewsWebp/Review5_336x348.webp';
import Review6WebpMobile from '@/shared/assets/images/Mobile/Reviews/ReviewsWebp/Review6_336x348.webp';
import Review7WebpMobile from '@/shared/assets/images/Mobile/Reviews/ReviewsWebp/Review7_336x348.webp';
import EnReview1AvifTablet from '@/shared/assets/images/Tablet/Reviews/EnVersion/Review1_Tablet.avif';
import EnReview1PngTablet from '@/shared/assets/images/Tablet/Reviews/EnVersion/Review1_Tablet.png';
import EnReview1WebpTablet from '@/shared/assets/images/Tablet/Reviews/EnVersion/Review1_Tablet.webp';
import EnReview2AvifTablet from '@/shared/assets/images/Tablet/Reviews/EnVersion/Review2_Tablet.avif';
import EnReview2PngTablet from '@/shared/assets/images/Tablet/Reviews/EnVersion/Review2_Tablet.png';
import EnReview2WebpTablet from '@/shared/assets/images/Tablet/Reviews/EnVersion/Review2_Tablet.webp';
import EnReview3AvifTablet from '@/shared/assets/images/Tablet/Reviews/EnVersion/Review3_Tablet.avif';
import EnReview3PngTablet from '@/shared/assets/images/Tablet/Reviews/EnVersion/Review3_Tablet.png';
import EnReview3WebpTablet from '@/shared/assets/images/Tablet/Reviews/EnVersion/Review3_Tablet.webp';
import EnReview4AvifTablet from '@/shared/assets/images/Tablet/Reviews/EnVersion/Review4_Tablet.avif';
import EnReview4PngTablet from '@/shared/assets/images/Tablet/Reviews/EnVersion/Review4_Tablet.png';
import EnReview4WebpTablet from '@/shared/assets/images/Tablet/Reviews/EnVersion/Review4_Tablet.webp';
import EnReview5AvifTablet from '@/shared/assets/images/Tablet/Reviews/EnVersion/Review5_Tablet.avif';
import EnReview5PngTablet from '@/shared/assets/images/Tablet/Reviews/EnVersion/Review5_Tablet.png';
import EnReview5WebpTablet from '@/shared/assets/images/Tablet/Reviews/EnVersion/Review5_Tablet.webp';
import EnReview6AvifTablet from '@/shared/assets/images/Tablet/Reviews/EnVersion/Review6_Tablet.avif';
import EnReview6PngTablet from '@/shared/assets/images/Tablet/Reviews/EnVersion/Review6_Tablet.png';
import EnReview6WebpTablet from '@/shared/assets/images/Tablet/Reviews/EnVersion/Review6_Tablet.webp';
import EnReview7AvifTablet from '@/shared/assets/images/Tablet/Reviews/EnVersion/Review7_Tablet.avif';
import EnReview7PngTablet from '@/shared/assets/images/Tablet/Reviews/EnVersion/Review7_Tablet.png';
import EnReview7WebpTablet from '@/shared/assets/images/Tablet/Reviews/EnVersion/Review7_Tablet.webp';
import Review1PngTablet from '@/shared/assets/images/Tablet/Reviews/ReviewPng/Review1_704x246.png';
import Review2PngTablet from '@/shared/assets/images/Tablet/Reviews/ReviewPng/Review2_704x246.png';
import Review3PngTablet from '@/shared/assets/images/Tablet/Reviews/ReviewPng/Review3_704x246.png';
import Review4PngTablet from '@/shared/assets/images/Tablet/Reviews/ReviewPng/Review4_704x246.png';
import Review5PngTablet from '@/shared/assets/images/Tablet/Reviews/ReviewPng/Review5_704x246.png';
import Review6PngTablet from '@/shared/assets/images/Tablet/Reviews/ReviewPng/Review6_704x246.png';
import Review7PngTablet from '@/shared/assets/images/Tablet/Reviews/ReviewPng/Review7_704x246.png';
import Review1AvifTablet from '@/shared/assets/images/Tablet/Reviews/ReviewsAvif/Review1_704x246.avif';
import Review2AvifTablet from '@/shared/assets/images/Tablet/Reviews/ReviewsAvif/Review2_704x246.avif';
import Review3AvifTablet from '@/shared/assets/images/Tablet/Reviews/ReviewsAvif/Review3_704x246.avif';
import Review4AvifTablet from '@/shared/assets/images/Tablet/Reviews/ReviewsAvif/Review4_704x246.avif';
import Review5AvifTablet from '@/shared/assets/images/Tablet/Reviews/ReviewsAvif/Review5_704x246.avif';
import Review6AvifTablet from '@/shared/assets/images/Tablet/Reviews/ReviewsAvif/Review6_704x246.avif';
import Review7AvifTablet from '@/shared/assets/images/Tablet/Reviews/ReviewsAvif/Review7_704x246.avif';
import Review1WebpTablet from '@/shared/assets/images/Tablet/Reviews/ReviewsWebp/Review1_704x246.webp';
import Review2WebpTablet from '@/shared/assets/images/Tablet/Reviews/ReviewsWebp/Review2_704x246.webp';
import Review3WebpTablet from '@/shared/assets/images/Tablet/Reviews/ReviewsWebp/Review3_704x246.webp';
import Review4WebpTablet from '@/shared/assets/images/Tablet/Reviews/ReviewsWebp/Review4_704x246.webp';
import Review5WebpTablet from '@/shared/assets/images/Tablet/Reviews/ReviewsWebp/Review5_704x246.webp';
import Review6WebpTablet from '@/shared/assets/images/Tablet/Reviews/ReviewsWebp/Review6_704x246.webp';
import Review7WebpTablet from '@/shared/assets/images/Tablet/Reviews/ReviewsWebp/Review7_704x246.webp';

export const arrReviews = (lang: string) =>
  lang === 'en'
    ? [
        {
          avif: [
            { media: 1440, src: EnReview1AvifDesktop },
            { media: 768, src: EnReview1AvifTablet },
            { media: 320, src: EnReview1AvifMobile },
          ],
          png: [
            { media: 1440, src: EnReview1PngDesktop },
            { media: 768, src: EnReview1PngTablet },
            { media: 320, src: EnReview1PngMobile },
          ],
          webp: [
            { media: 1440, src: EnReview1WebpDesktop },
            { media: 768, src: EnReview1WebpTablet },
            { media: 320, src: EnReview1WebpMobile },
          ],
        },
        {
          avif: [
            { media: 1440, src: EnReview2AvifDesktop },
            { media: 768, src: EnReview2AvifTablet },
            { media: 320, src: EnReview2AvifMobile },
          ],
          png: [
            { media: 1440, src: EnReview2PngDesktop },
            { media: 768, src: EnReview2PngTablet },
            { media: 320, src: EnReview2PngMobile },
          ],
          webp: [
            { media: 1440, src: EnReview2WebpDesktop },
            { media: 768, src: EnReview2WebpTablet },
            { media: 320, src: EnReview2WebpMobile },
          ],
        },
        {
          avif: [
            { media: 1440, src: EnReview3AvifDesktop },
            { media: 768, src: EnReview3AvifTablet },
            { media: 320, src: EnReview3AvifMobile },
          ],
          png: [
            { media: 1440, src: EnReview3PngDesktop },
            { media: 768, src: EnReview3PngTablet },
            { media: 320, src: EnReview3PngMobile },
          ],
          webp: [
            { media: 1440, src: EnReview3WebpDesktop },
            { media: 768, src: EnReview3WebpTablet },
            { media: 320, src: EnReview3WebpMobile },
          ],
        },
        {
          avif: [
            { media: 1440, src: EnReview4AvifDesktop },
            { media: 768, src: EnReview4AvifTablet },
            { media: 320, src: EnReview4AvifMobile },
          ],
          png: [
            { media: 1440, src: EnReview4PngDesktop },
            { media: 768, src: EnReview4PngTablet },
            { media: 320, src: EnReview4PngMobile },
          ],
          webp: [
            { media: 1440, src: EnReview4WebpDesktop },
            { media: 768, src: EnReview4WebpTablet },
            { media: 320, src: EnReview4WebpMobile },
          ],
        },
        {
          avif: [
            { media: 1440, src: EnReview5AvifDesktop },
            { media: 768, src: EnReview5AvifTablet },
            { media: 320, src: EnReview5AvifMobile },
          ],
          png: [
            { media: 1440, src: EnReview5PngDesktop },
            { media: 768, src: EnReview5PngTablet },
            { media: 320, src: EnReview5PngMobile },
          ],
          webp: [
            { media: 1440, src: EnReview5WebpDesktop },
            { media: 768, src: EnReview5WebpTablet },
            { media: 320, src: EnReview5WebpMobile },
          ],
        },
        {
          avif: [
            { media: 1440, src: EnReview6AvifDesktop },
            { media: 768, src: EnReview6AvifTablet },
            { media: 320, src: EnReview6AvifMobile },
          ],
          png: [
            { media: 1440, src: EnReview6PngDesktop },
            { media: 768, src: EnReview6PngTablet },
            { media: 320, src: EnReview6PngMobile },
          ],
          webp: [
            { media: 1440, src: EnReview6WebpDesktop },
            { media: 768, src: EnReview6WebpTablet },
            { media: 320, src: EnReview6WebpMobile },
          ],
        },
        {
          avif: [
            { media: 1440, src: EnReview7AvifDesktop },
            { media: 768, src: EnReview7AvifTablet },
            { media: 320, src: EnReview7AvifMobile },
          ],
          png: [
            { media: 1440, src: EnReview7PngDesktop },
            { media: 768, src: EnReview7PngTablet },
            { media: 320, src: EnReview7PngMobile },
          ],
          webp: [
            { media: 1440, src: EnReview7WebpDesktop },
            { media: 768, src: EnReview7WebpTablet },
            { media: 320, src: EnReview7WebpMobile },
          ],
        },
      ]
    : [
        {
          avif: [
            { media: 1440, src: Review1AvifDesktop },
            { media: 768, src: Review1AvifTablet },
            { media: 320, src: Review1AvifMobile },
          ],
          png: [
            { media: 1440, src: Review1PngDesktop },
            { media: 768, src: Review1PngTablet },
            { media: 320, src: Review1PngMobile },
          ],
          webp: [
            { media: 1440, src: Review1WebpDesktop },
            { media: 768, src: Review1WebpTablet },
            { media: 320, src: Review1WebpMobile },
          ],
        },
        {
          avif: [
            { media: 1440, src: Review2AvifDesktop },
            { media: 768, src: Review2AvifTablet },
            { media: 320, src: Review2AvifMobile },
          ],
          png: [
            { media: 1440, src: Review2PngDesktop },
            { media: 768, src: Review2PngTablet },
            { media: 320, src: Review2PngMobile },
          ],
          webp: [
            { media: 1440, src: Review2WebpDesktop },
            { media: 768, src: Review2WebpTablet },
            { media: 320, src: Review2WebpMobile },
          ],
        },
        {
          avif: [
            { media: 1440, src: Review3AvifDesktop },
            { media: 768, src: Review3AvifTablet },
            { media: 320, src: Review3AvifMobile },
          ],
          png: [
            { media: 1440, src: Review3PngDesktop },
            { media: 768, src: Review3PngTablet },
            { media: 320, src: Review3PngMobile },
          ],
          webp: [
            { media: 1440, src: Review3WebpDesktop },
            { media: 768, src: Review3WebpTablet },
            { media: 320, src: Review3WebpMobile },
          ],
        },
        {
          avif: [
            { media: 1440, src: Review4AvifDesktop },
            { media: 768, src: Review4AvifTablet },
            { media: 320, src: Review4AvifMobile },
          ],
          png: [
            { media: 1440, src: Review4PngDesktop },
            { media: 768, src: Review4PngTablet },
            { media: 320, src: Review4PngMobile },
          ],
          webp: [
            { media: 1440, src: Review4WebpDesktop },
            { media: 768, src: Review4WebpTablet },
            { media: 320, src: Review4WebpMobile },
          ],
        },
        {
          avif: [
            { media: 1440, src: Review5AvifDesktop },
            { media: 768, src: Review5AvifTablet },
            { media: 320, src: Review5AvifMobile },
          ],
          png: [
            { media: 1440, src: Review5PngDesktop },
            { media: 768, src: Review5PngTablet },
            { media: 320, src: Review5PngMobile },
          ],
          webp: [
            { media: 1440, src: Review5WebpDesktop },
            { media: 768, src: Review5WebpTablet },
            { media: 320, src: Review5WebpMobile },
          ],
        },
        {
          avif: [
            { media: 1440, src: Review6AvifDesktop },
            { media: 768, src: Review6AvifTablet },
            { media: 320, src: Review6AvifMobile },
          ],
          png: [
            { media: 1440, src: Review6PngDesktop },
            { media: 768, src: Review6PngTablet },
            { media: 320, src: Review6PngMobile },
          ],
          webp: [
            { media: 1440, src: Review6WebpDesktop },
            { media: 768, src: Review6WebpTablet },
            { media: 320, src: Review6WebpMobile },
          ],
        },
        {
          avif: [
            { media: 1440, src: Review7AvifDesktop },
            { media: 768, src: Review7AvifTablet },
            { media: 320, src: Review7AvifMobile },
          ],
          png: [
            { media: 1440, src: Review7PngDesktop },
            { media: 768, src: Review7PngTablet },
            { media: 320, src: Review7PngMobile },
          ],
          webp: [
            { media: 1440, src: Review7WebpDesktop },
            { media: 768, src: Review7WebpTablet },
            { media: 320, src: Review7WebpMobile },
          ],
        },
      ];
