import { useTranslations } from 'next-intl';
import { FC, memo, useRef } from 'react';
import cls from './welcomeBlock.module.css';
import { showCurrentBlock } from '../../../model/lib/showCurrentBlock';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { useObserver } from '@/shared/lib/hooks/useObserver';
import { HStack, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

interface IWelcomeBlockProps {
  className?: string;
}

export const WelcomeBlock: FC<IWelcomeBlockProps> = memo(props => {
  const { className } = props;
  const t = useTranslations();
  const blockRef = useRef<null | HTMLDivElement>(null);
  const blockId = 'startBlock';
  const { isDesktop, isTabletHorizontal } = useDetectDevice();

  useObserver({
    handler: showCurrentBlock,
    id: `header-${blockId}`,
    ref: blockRef,
    rootMargin: '120px',
    threshold: 0.4,
  });

  return (
    <HStack
      ref={blockRef}
      max
      id={blockId}
      align={isDesktop || isTabletHorizontal ? 'center' : 'start'}
      className={classNames(cls['welcome-block'], {}, [className])}
    >
      <VStack gap='x-l'>
        <VStack>
          <Text variant='h1' as='h1' className={cls.hello}>
            {t('Hello')}
          </Text>
          <Text variant='h1' as='h1' className={cls.jerold}>
            {t('I Jerold')}
          </Text>
        </VStack>
        <Text variant='body-l' className={cls.paragraph1}>
          {t('I your personal assistant')}
        </Text>
        <Text
          variant='h3'
          as='h3'
          className={classNames(cls.paragraph1, {}, [cls.paragraph2])}
        >
          {t('My goal is to help')}
        </Text>
      </VStack>
    </HStack>
  );
});
