import { useTranslations } from 'next-intl';
import {
  Dispatch,
  FC,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cls from './screenerPriceBlock.module.css';
import { showCurrentBlock } from '../../../../../model/lib/showCurrentBlock';
import { DescriptionBlock } from '../DescriptionBlock/DescriptionBlock';
import { ScreenerBlockSkeleton } from '../ScreenerBlockSkeleton/ScreenerBlockSkeleton';
import { ScreenerPricePackagesBlock } from '../ScreenerPricePackagesBlock/ScreenerPricePackagesBlock';
import {
  getPackageIsLoading,
  fetchPackage,
  QueryParamsForAllPackages,
} from '@/entities/Package';
import { classNames } from '@/shared/lib/classNames/classNames';
import { getFeatureFlags } from '@/shared/lib/features';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { useObserver, useSimpleObserver } from '@/shared/lib/hooks/useObserver';
import { useQueryParams } from '@/shared/lib/hooks/useQueryParams';
import { VStack, Flex } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';
import { TriangleNew } from '@/shared/ui/TriangleNew';

interface TTtypeTabs {
  name: 'screener' | 'signals' | 'copytrading';
  id: string;
}

interface IScreenerPriceBlockProps {
  className?: string;
  toShowSecondMessage: Dispatch<SetStateAction<boolean>>;
}

export const ScreenerPriceBlock: FC<IScreenerPriceBlockProps> = memo(props => {
  const { className, toShowSecondMessage } = props;
  const t = useTranslations();
  const dispatch = useAppDispatch();
  const searchParams = useQueryParams();

  const isCopytrading = getFeatureFlags('isCopytrading');

  const isLoading = useAppSelector(getPackageIsLoading);

  const { isDesktop, isMobile } = useDetectDevice();
  const blockRef = useRef<null | HTMLDivElement>(null);
  const blockId = 'pricingBlock';

  useObserver({
    handler: showCurrentBlock,
    id: `header-${blockId}`,
    ref: blockRef,
    rootMargin: '120px',
    threshold: 1,
  });
  useSimpleObserver(blockRef, toShowSecondMessage);

  const [tab, setTab] = useState<TTtypeTabs>(
    isCopytrading
      ? {
          id: 'copytrading',
          name: 'copytrading',
        }
      : {
          id: 'screener',
          name: 'screener',
        },
  );

  const allTabs: TTtypeTabs[] = isCopytrading
    ? [
        { id: 'copytrading', name: 'copytrading' },
        { id: 'screener', name: 'screener' },
        { id: 'signals', name: 'signals' },
      ]
    : [
        { id: 'screener', name: 'screener' },
        { id: 'signals', name: 'signals' },
      ];

  const changeTabs = (itemTabs: TTtypeTabs) => setTab(itemTabs);

  const rightAlign = useMemo(
    () => (isDesktop ? 'start' : 'center'),
    [isDesktop],
  );
  const rightDirection = useMemo(
    () => (isDesktop ? 'row' : 'column'),
    [isDesktop],
  );

  const getPackages = useCallback(
    (productType: QueryParamsForAllPackages) => {
      dispatch(
        fetchPackage({ isPartner: Boolean(searchParams.ref), productType }),
      );
    },
    [dispatch, searchParams.ref],
  );

  useEffect(() => {
    process.env.NEXT_PUBLIC_COPYTRADING !== 'disabled' &&
      getPackages('copy-trading');
    getPackages('external');
    getPackages('main');
  }, [getPackages]);

  const contentBlocks = useMemo(
    () => (
      <Flex
        className={cls['main-block']}
        align={rightAlign}
        direction={rightDirection}
        gap={isMobile ? 'x-l' : '2-x-l'}
      >
        {tab.id !== 'signals' && <TriangleNew className={cls.new} />}
        <DescriptionBlock typePackages={tab.name} />
        <ScreenerPricePackagesBlock
          typePackages={tab.name}
          isPartner={Boolean(searchParams.ref)}
        />
      </Flex>
    ),
    [isMobile, rightAlign, rightDirection, searchParams.ref, tab],
  );

  const content = useMemo(() => {
    if (isLoading) return <ScreenerBlockSkeleton />;
    return contentBlocks;
  }, [isLoading, contentBlocks]);

  return (
    <VStack
      ref={blockRef}
      max
      id={blockId}
      align='center'
      justify='end'
      className={classNames(cls['screener-price-block'], {}, [className])}
    >
      <Text as='h2' variant='h2'>
        {t('Prices')}
      </Text>
      <>
        {/* <Tabs className={className} items={allTabs} onChangeItem={changeTabs} /> */}
        {/* {tab.id === 'signals' && <DiscountBlock />} */}
        {/* {content} */}

        <Flex
          className={cls['main-block']}
          align={rightAlign}
          direction={rightDirection}
          gap={isMobile ? 'x-l' : '2-x-l'}
        >
          {/* {tab.id !== 'signals' && <TriangleNew className={cls.new} />} */}
          <DescriptionBlock typePackages={tab.name} />
          <ScreenerPricePackagesBlock
            typePackages={tab.name}
            isPartner={Boolean(searchParams.ref)}
          />
        </Flex>
      </>
    </VStack>
  );
});
