import { useTranslations } from 'next-intl';
import { FC, memo, useMemo, useRef, useState } from 'react';
import { ItemResultBlock } from './ItemResultBlock/ItemResultBlock';
import cls from './resultBlock.module.css';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { useSimpleObserver } from '@/shared/lib/hooks/useObserver';
import { GStack, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

interface IResultBlockProps {
  className?: string;
}

export const ResultBlock: FC<IResultBlockProps> = memo(props => {
  const { className } = props;
  const t = useTranslations();
  const [onVisible, setOnVisible] = useState(false);
  const blockRef = useRef<null | HTMLDivElement>(null);
  const { isDesktop, isTabletHorizontal, isTablet, isMobile } =
    useDetectDevice();

  useSimpleObserver(blockRef, setOnVisible);

  const gtc = useMemo(() => {
    if (isDesktop) return 'repeat(4, 1fr)';
    if (isTabletHorizontal || isTablet) return '1fr 1fr';
    return '1fr';
  }, [isDesktop, isTabletHorizontal, isTablet]);

  const gtr = useMemo(() => {
    if (isTabletHorizontal || isTablet) return '1fr 1fr';
    if (isMobile) return 'repeat(4, 1fr)';
    return '1fr';
  }, [isMobile, isTabletHorizontal, isTablet]);

  const items = useMemo(
    () => [
      {
        maxCount: 85,
        subtitle: t('Instruments move in the forecasted direction'),
        title: t('Up to'),
        title2: '%',
      },
      {
        maxCount: 1,
        subtitle: t('I process more than 1 terabyte of data per day'),
        title: '>',
        title2: t('TB'),
      },
      {
        maxCount: 1000,
        subtitle: t('Users are already using the Screener on Forex'),
        title2: '+',
      },
      {
        maxCount: 500,
        subtitle: t('Of positive reviews'),
        title2: '+',
      },
    ],
    [t],
  );

  return (
    <VStack
      ref={blockRef}
      max
      justify='center'
      className={classNames(cls['result-block'], {}, [className])}
    >
      <VStack gap='x-l'>
        <Text as='h2' variant='h2'>
          {t('14 years of continuous development and refinement')}
        </Text>
        <Text variant='body-l'>{t('result description')}</Text>
      </VStack>
      <VStack max className={cls['items-wrapper']}>
        <Text as='h3' variant='h3'>
          {t('What the result are like')}
        </Text>
        <GStack className={cls.items} gtc={gtc} gtr={gtr}>
          {items.map(item => (
            <ItemResultBlock
              key={item.subtitle}
              item={item}
              onVisible={onVisible}
            />
          ))}
        </GStack>
      </VStack>
    </VStack>
  );
});
