import { useTranslations } from 'next-intl';
import { FC, memo, MouseEvent, SVGProps, useRef } from 'react';
import cls from './ItemBrainTablet.module.css';
import { ArrowLendingUpGreenSVG } from '@/shared/assets/svg/Main/Arrows';
import { classNames } from '@/shared/lib/classNames/classNames';
import useOnClickOutside from '@/shared/lib/hooks/onClickOutside';
import { Button } from '@/shared/ui/Buttons';
import { Icon } from '@/shared/ui/Icon';
import { HStack, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

interface IItemBrainTabletProps {
  className?: string;
  item: {
    header: string;
    icon: FC<SVGProps<SVGSVGElement>>;
    subtitle: string;
    text: string;
  };
}

export const ItemBrainTablet: FC<IItemBrainTabletProps> = memo(props => {
  const { className, item } = props;
  const t = useTranslations();
  const ref = useRef<null | HTMLDivElement>(null);
  const refBox = useRef<null | HTMLDivElement>(null);

  const goScroll = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    refBox.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  };

  const close = () => {
    ref.current?.scroll({
      behavior: 'smooth',
      left: 0,
    });
  };

  useOnClickOutside(ref, close);

  return (
    <HStack
      ref={ref}
      className={classNames(cls['item-brain-block'], {}, [className])}
      align='center'
      onClick={close}
    >
      <VStack className={cls['first-block']}>
        <div className={cls['icon-wrapper']}>
          <Icon Svg={item.icon} width={42} height={42} color='#4CFEC9' />
        </div>
        <VStack className={cls['text-wrapper']}>
          <Text as='h3' variant='h3'>
            {item.header}
          </Text>
          <Text as='h4' variant='body-m'>
            {item.subtitle}
          </Text>
        </VStack>
        <Button
          capitalize
          text={t('More')}
          imgLocation='left'
          uppercase={false}
          className={cls.button}
          img={ArrowLendingUpGreenSVG}
          typeButton='link'
          onClick={goScroll}
        />
      </VStack>
      <VStack ref={refBox} gap='x-l' className={cls['second-block']}>
        <Text as='h3' variant='h3'>
          {item.header}
        </Text>
        <Text variant='body-m'>{item.text}</Text>
      </VStack>
    </HStack>
  );
});
