import { useLocale, useTranslations } from 'next-intl';
import { FC, memo, useMemo } from 'react';
import cls from './promoBanner.module.css';
import { getRoutePromoPage } from '@/shared/const/router';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useAppNavigation } from '@/shared/lib/hooks/useAppNavigation/useAppNavigation';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { Button } from '@/shared/ui/Buttons';
import { Flex, HStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

interface IPromoBannerProps {
  className?: string;
  isConsentMode?: boolean;
}

export const PromoBanner: FC<IPromoBannerProps> = memo(props => {
  const { className, isConsentMode } = props;
  const t = useTranslations();
  const { push } = useAppNavigation();
  const lang = useLocale();

  const { isMobile, isDesktop } = useDetectDevice();

  const toPromoPage = () => push({ path: getRoutePromoPage() });

  const gapBannerWrapper = useMemo(
    () => (isMobile ? 's' : '3-x-l'),
    [isMobile],
  );
  const directionBannerWrapper = useMemo(
    () => (isMobile ? 'column' : 'row'),
    [isMobile],
  );
  const bottomBannerWrapper = useMemo(
    () => (isConsentMode ? {} : { bottom: isMobile ? 0 : '40px' }),
    [isConsentMode, isMobile],
  );

  const gapButtonWrapper = useMemo(() => {
    if (isMobile) return 'x-x-s';
    if (isDesktop) return 'x-l';
    return 'x-s';
  }, [isDesktop, isMobile]);
  const directionButtonWrapper = useMemo(
    () => (isDesktop ? 'row' : 'column'),
    [isDesktop],
  );
  const textButton = useMemo(
    () => (lang === 'ru' ? 'Купить подписку' : 'Subscribe'),
    [lang],
  );

  const textInBanner = useMemo(
    () =>
      lang === 'ru' ? (
        <Text
          variant='h4-uppercase'
          as='h4'
          align={isMobile ? 'center' : 'start'}
          style={{ width: isDesktop ? 'auto' : '300px' }}
        >
          {'Покупай подписку на '}
          <span>{'3'}</span>
          {' месяца по цене '}
          <span>{'2-X'}</span>
          {'!'}
        </Text>
      ) : (
        <Text
          variant='h4-uppercase'
          as='h4'
          align={isMobile ? 'center' : 'start'}
          style={{ width: isDesktop ? 'auto' : '300px' }}
        >
          {'Pay for '}
          <span>{'2'}</span>
          {' months access, '}
          {' get '}
          <span>{'3'}</span>
          {' months!'}
        </Text>
      ),
    [isDesktop, isMobile, lang],
  );

  return (
    <Flex
      className={classNames(cls['promo-banner'], {}, [className])}
      justify='between'
      gap={gapBannerWrapper}
      direction={directionBannerWrapper}
      style={bottomBannerWrapper}
    >
      <HStack gap='x-l' align='center'>
        {!isMobile && (
          <Text variant='body-l' className={cls.title}>
            {t('sale')}
          </Text>
        )}
        {textInBanner}
      </HStack>
      <Flex
        direction={directionButtonWrapper}
        justify='end'
        max={isMobile}
        gap={gapButtonWrapper}
        style={{ minWidth: '10.625rem' }}
      >
        <Text variant='body-s'>{t('valid till 31 of May')}</Text>
        <Button
          text={textButton}
          typeButton='accent'
          size='small'
          fullWidth={isMobile}
          onClick={toPromoPage}
        />
      </Flex>
      <div className={cls['btn-background']} />
    </Flex>
  );
});
