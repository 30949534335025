import AvifScreenerDesktop from '@/shared/assets/images/Desktop/Screener/Screener_Desktop.avif';
import PngScreenerDesktop from '@/shared/assets/images/Desktop/Screener/Screener_Desktop.png';
import WebpScreenerDesktop from '@/shared/assets/images/Desktop/Screener/Screener_Desktop.webp';
import AvifScreenerMobile from '@/shared/assets/images/Mobile/Screener/Screener_Mobile.avif';
import PngScreenerMobile from '@/shared/assets/images/Mobile/Screener/Screener_Mobile.png';
import WebpScreenerMobile from '@/shared/assets/images/Mobile/Screener/Screener_Mobile.webp';
import AvifScreenerTablet from '@/shared/assets/images/Tablet/Screener/Screener_Tablet.avif';
import PngScreenerTablet from '@/shared/assets/images/Tablet/Screener/Screener_Tablet.png';
import WebpScreenerTablet from '@/shared/assets/images/Tablet/Screener/Screener_Tablet.webp';

export const screenerBlockImages = () => [
  {
    alt: 'Screener',
    avif: [
      { media: 1440, src: AvifScreenerDesktop },
      { media: 768, src: AvifScreenerTablet },
      { media: 320, src: AvifScreenerMobile },
    ],
    png: [
      { media: 1440, src: PngScreenerDesktop },
      { media: 768, src: PngScreenerMobile },
      { media: 320, src: PngScreenerTablet },
    ],
    webp: [
      { media: 1440, src: WebpScreenerDesktop },
      { media: 768, src: WebpScreenerMobile },
      { media: 320, src: WebpScreenerTablet },
    ],
  },
];
