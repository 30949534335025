import { FC, memo, useMemo, useRef } from 'react';
import cls from './packagesSkeleton.module.css';
import { packagesCalcSkeletonSize } from '../model/lib/helpers/packagesCalcSkeletonSize';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { Skeleton } from '@/shared/ui/Skeleton/Skeleton';
import { Flex, VStack } from '@/shared/ui/Stack';

interface IPackagesSkeletonProps {
  className?: string;
}

export const PackagesSkeleton: FC<IPackagesSkeletonProps> = memo(props => {
  const { className } = props;
  const { isDesktop, isMobile, isTablet } = useDetectDevice();
  const blockRef = useRef<null | HTMLDivElement>(null);
  const blockId = 'pricingBlock';

  const { left, right, tabs, discount } = packagesCalcSkeletonSize(
    isMobile,
    isTablet,
  );

  const size = useMemo(() => {
    if (isMobile) return 12;
    return 25;
  }, [isMobile]);

  const slides = Array.from({ length: size }, (v, i) => i + 1);

  const alignWrapper = useMemo(
    () => (isDesktop ? 'start' : 'center'),
    [isDesktop],
  );
  const directionWrapper = useMemo(
    () => (isDesktop ? 'row' : 'column'),
    [isDesktop],
  );

  const alignRightSide = useMemo(
    () => (isMobile ? 'center' : 'start'),
    [isMobile],
  );
  const justifyRightSide = useMemo(
    () => (isDesktop ? 'start' : 'center'),
    [isDesktop],
  );
  const directionRightSide = useMemo(
    () => (isMobile ? 'column' : 'row'),
    [isMobile],
  );

  const widthLeftDiscount = useMemo(() => {
    if (isMobile) return '240px';
    if (isTablet) return '368px';
    return '560px';
  }, [isMobile, isTablet]);

  const widthRightDiscount = useMemo(() => {
    if (isMobile) return '240px';
    if (isTablet) return '232px';
    return '250px';
  }, [isMobile, isTablet]);

  const directionDiscount = useMemo(
    () => (isMobile ? 'column' : 'row'),
    [isMobile],
  );

  return (
    <VStack
      ref={blockRef}
      max
      id={blockId}
      align='center'
      justify='end'
      gap='l'
      className={classNames(cls['packages-block-skeleton'], {}, [className])}
    >
      <Skeleton
        width='256px'
        height={tabs}
        className={classNames(cls.skeleton, {}, [cls.tabs])}
        border='24px'
      />
      <Flex
        max
        gap='x-l'
        direction={directionDiscount}
        justify='between'
        className={cls['discount-block']}
      >
        <Skeleton
          width={widthLeftDiscount}
          height={discount.left}
          className={cls.block}
        />
        <Skeleton
          width={widthRightDiscount}
          height={discount.right}
          className={cls.block}
        />
      </Flex>
      <Flex
        className={cls['main-block-skeleton']}
        align={alignWrapper}
        direction={directionWrapper}
      >
        <VStack
          max
          justify='between'
          align='start'
          className={cls['left-side-skeleton']}
        >
          <Skeleton width='90px' height={left.title} className={cls.skeleton} />
          <Skeleton
            width='140px'
            height={left.subtitle}
            className={classNames(cls.skeleton, {}, [cls.subtitle])}
          />
          <Flex
            wrap='wrap'
            direction='row'
            justify='start'
            className={cls['instruments-skeleton']}
          >
            {slides.map((item, index) => (
              <Skeleton
                key={index}
                width='102px'
                height={left.slide}
                className={cls.skeleton}
              />
            ))}
          </Flex>
        </VStack>
        <Flex
          direction={directionRightSide}
          align={alignRightSide}
          justify={justifyRightSide}
          gap='x-l'
        >
          <VStack justify='between' className={cls['item-packages-skeleton']}>
            <VStack gap='x-l' className={cls['right-items-skeleton']}>
              <Skeleton
                width='100%'
                height={right.firstRow}
                className={cls.skeleton}
              />
              <Skeleton
                width='90px'
                height={right.secondRow}
                className={cls.skeleton}
              />
              <Skeleton
                width='100%'
                height={right.thirdRow}
                className={cls.skeleton}
              />
            </VStack>
            <Skeleton
              width='100%'
              height={right.fourthRow}
              className={cls.skeleton}
              border='24px'
            />
          </VStack>
          <VStack justify='between' className={cls['item-packages-skeleton']}>
            <VStack gap='x-l' className={cls['right-items-skeleton']}>
              <Skeleton
                width='100%'
                height={right.firstRow}
                className={cls.skeleton}
              />
              <Skeleton
                width='90px'
                height={right.secondRow}
                className={cls.skeleton}
              />
              <Skeleton
                width='100%'
                height={right.thirdRow}
                className={cls.skeleton}
              />
            </VStack>
            <Skeleton
              width='100%'
              height={right.fourthRow}
              className={cls.skeleton}
              border='24px'
            />
          </VStack>
        </Flex>
      </Flex>
    </VStack>
  );
});
