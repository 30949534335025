import { FC } from 'react';
import cls from './slide.module.css';
import { TradeInstruments } from '@/entities/Package';
import { classNames } from '@/shared/lib/classNames/classNames';
import { AppImage } from '@/shared/ui/AppImage';
import { HStack, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

export const ContentSlide = (props: TradeInstruments) => {
  const { name, iconLink } = props;

  return (
    <HStack gap='x-s' align='center' style={{ width: '6.375rem' }}>
      {!!iconLink && (
        <AppImage src={iconLink} alt={name} width='20' height='20' />
      )}
      <Text as='span' variant='body-s' style={{ textTransform: 'uppercase' }}>
        {name}
      </Text>
    </HStack>
  );
};

interface ISlideProps {
  className?: string;
  partArray: TradeInstruments[];
}

export const Slide: FC<ISlideProps> = props => {
  const { className, partArray } = props;

  return (
    <VStack max gap='m' className={classNames(cls.slide, {}, [className])}>
      <VStack max gap='m'>
        <HStack gap='m' wrap='wrap'>
          {partArray.map(({ iconLink, id, name }) => (
            <ContentSlide key={id} id={id} iconLink={iconLink} name={name} />
          ))}
        </HStack>
      </VStack>
    </VStack>
  );
};
