import { useTranslations } from 'next-intl';
import { FC, SVGProps, memo, useRef, MouseEvent } from 'react';
import cls from './ItemBrainDesktop.module.css';
import { ArrowLendingUpGreenSVG } from '@/shared/assets/svg/Main/Arrows';
import { classNames } from '@/shared/lib/classNames/classNames';
import useOnClickOutside from '@/shared/lib/hooks/onClickOutside';
import { Button } from '@/shared/ui/Buttons';
import { Icon } from '@/shared/ui/Icon';
import { VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

interface IItemBrainDesktopProps {
  className?: string;
  item: {
    header: string;
    icon: FC<SVGProps<SVGSVGElement>>;
    subtitle: string;
    text: string;
  };
}

export const ItemBrainDesktop: FC<IItemBrainDesktopProps> = memo(props => {
  const { className, item } = props;
  const t = useTranslations();
  const ref = useRef<null | HTMLDivElement>(null);
  const refBox = useRef<null | HTMLDivElement>(null);

  const goScroll = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (ref.current) ref.current?.classList.add(cls['item-brain-block-active']);
    if (ref.current)
      ref.current?.classList.remove(cls['item-brain-block-unactive']);

    refBox.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };

  const close = () => {
    ref.current?.scroll({ behavior: 'smooth', top: 0 });
    if (ref.current?.classList.contains(cls['item-brain-block-active']))
      ref.current?.classList.add(cls['item-brain-block-unactive']);
    if (ref.current)
      ref.current?.classList.remove(cls['item-brain-block-active']);
  };

  useOnClickOutside(ref, close);

  return (
    <VStack
      ref={ref}
      gap='m'
      align='end'
      className={classNames(cls['item-brain-block'], {}, [className])}
      onClick={close}
    >
      <div className={cls['icon-wrapper']}>
        <Icon Svg={item.icon} width={42} height={42} color='#4CFEC9' />
      </div>
      <Text as='h3' variant='h3'>
        {item.header}
      </Text>
      <Text as='h4' variant='body-m'>
        {item.subtitle}
      </Text>
      <Button
        capitalize
        uppercase={false}
        img={ArrowLendingUpGreenSVG}
        typeButton='link'
        className={cls.button}
        text={t('More')}
        imgLocation='left'
        onClick={goScroll}
      />
      <VStack ref={refBox} className={cls['second-block']}>
        <Text as='h3' variant='h3'>
          {item.header}
        </Text>
        <Text variant='body-m'>{item.text}</Text>
      </VStack>
    </VStack>
  );
});
