import { useTranslations } from 'next-intl';
import { FC, memo, useMemo } from 'react';
import cls from './descriptionBlock.module.css';
import {
  ImportantSVG,
  ScreenerSVG,
  SignalsSVG,
  CopySimple,
} from '@/shared/assets/svg/Main/Screener';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { Icon } from '@/shared/ui/Icon';
import { HStack, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

interface IDescriptionBlockProps {
  className?: string;
  typePackages: 'screener' | 'signals' | 'copytrading';
}

export const DescriptionBlock: FC<IDescriptionBlockProps> = memo(props => {
  const { className, typePackages } = props;

  const t = useTranslations();
  const { isMobile } = useDetectDevice();

  const icon = useMemo(() => {
    if (typePackages === 'screener') return ScreenerSVG;
    if (typePackages === 'signals') return SignalsSVG;
    return CopySimple;
  }, [typePackages]);

  const heading = useMemo(() => {
    if (typePackages === 'screener') return 'Screener';
    if (typePackages === 'signals') return 'Trading signals';
    return 'Copytrading';
  }, [typePackages]);

  const paragraphs = useMemo(() => {
    if (typePackages === 'screener')
      return [
        {
          description: t(
            'A list of instruments with levels and directions that you will receive throughout the trading day',
          ),
          title: t('Supply and demand levels formed by big players'),
        },
        {
          description: t('Set alerts for the price levels'),
          title: t('Set price level alerts'),
        },
      ];
    if (typePackages === 'signals')
      return [
        {
          description: t(
            'In each trading signal you will get either limit or market order to enter the position',
          ),
          title: t('Limit and market orders'),
        },
        {
          description: t(
            'In each trading signal you will also get 3 take profits and Stop-loss',
          ),
          title: '3 Take Profit',
        },
      ];
    return [
      {
        description: t(
          'Copy trading with our partner BYBIT opens up new opportunities for passive income',
        ),
        title: t('Earnings with BYBIT'),
      },
      {
        description: t(
          'A separate account that copies the trades of AI Jerold offers new opportunities',
        ),
        title: t('Diversification with AI Jerold'),
      },
    ];
  }, [typePackages, t]);

  const headingJustify = useMemo(
    () => (isMobile ? 'center' : 'start'),
    [isMobile],
  );
  const titleFont = useMemo(
    () => (isMobile ? 'body-m-medium' : 'body-l-medium'),
    [isMobile],
  );
  const descriptionFont = useMemo(
    () => (isMobile ? 'body-s' : 'body-m'),
    [isMobile],
  );

  return (
    <VStack
      max
      className={classNames(cls['description-block'], {}, [className])}
      gap='m'
      justify='center'
    >
      <HStack
        gap='s'
        className={cls.heading}
        justify={headingJustify}
        max={isMobile}
      >
        <Icon Svg={icon} width={32} height={32} />
        <Text as='h4' variant='h4-uppercase'>
          {heading}
        </Text>
      </HStack>
      {paragraphs.map(({ title, description }) => (
        <HStack key={title} gap='x-s' align='start'>
          <HStack className={cls.important}>
            <Icon Svg={ImportantSVG} width={16} height={16} />
          </HStack>
          <VStack gap='x-x-s'>
            <Text as='h6' variant={titleFont}>
              {title}
            </Text>
            <Text variant={descriptionFont}>{description}</Text>
          </VStack>
        </HStack>
      ))}
    </VStack>
  );
});
