import { useTranslations } from 'next-intl';
import { FC, Key, memo, useCallback, useMemo } from 'react';
import { ItemBrainDesktop } from './ItemBrainDesktop/ItemBrainDesktop';
import { ItemBrainTablet } from './ItemBrainTablet/ItemBrainTablet';
import cls from './brainBlock.module.css';
import {
  BiologySVG,
  MathematicsSVG,
  PhysicsSVG,
} from '@/shared/assets/svg/BrainBlock';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { Flex, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

interface IBrainBlockProps {
  className?: string;
}

export const BrainBlock: FC<IBrainBlockProps> = memo(props => {
  const { className } = props;
  const t = useTranslations();
  const { isDesktop, isTabletHorizontal } = useDetectDevice();

  const items = useMemo(
    () => [
      {
        header: t('Biology'),
        icon: BiologySVG,
        subtitle: t('Biology description'),
        text: t('Biology text'),
      },
      {
        header: t('Physics'),
        icon: PhysicsSVG,
        subtitle: t('Physics description'),
        text: t('Physics text'),
      },
      {
        header: t('Mathematics'),
        icon: MathematicsSVG,
        subtitle: t('Mathematics description'),
        text: t('Mathematics text'),
      },
    ],
    [t],
  );

  const renderContent = useCallback(
    // eslint-disable-next-line react/no-unused-prop-types
    ({ item, key }: { item: (typeof items)[0]; key?: Key | null }) =>
      isDesktop || isTabletHorizontal ? (
        <ItemBrainDesktop key={key} item={item} />
      ) : (
        <ItemBrainTablet key={key} item={item} />
      ),
    [isDesktop, isTabletHorizontal],
  );

  return (
    <VStack
      max
      justify='center'
      className={classNames(cls['brain-block'], {}, [className])}
    >
      <VStack gap='2-x-l'>
        <Text as='h2' variant='h2'>
          {t('I was developed based on 3 elements')}
        </Text>
        <Text variant='body-l' className={cls.description}>
          {t('My trading signals are generated using a cutting-edge framework')}
        </Text>
      </VStack>
      <Flex
        max
        direction={isDesktop || isTabletHorizontal ? 'row' : 'column'}
        className={cls['scrolled-items']}
      >
        {items.map(item => renderContent({ item, key: item.header }))}
      </Flex>
    </VStack>
  );
});
