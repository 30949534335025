import { useTranslations } from 'next-intl';
import {
  Dispatch,
  FC,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PackageAside from './PackageAside';
import cls from './packagesBlock.module.css';
import { showCurrentBlock } from '../../../../../model/lib/showCurrentBlock';
import { DiscountBlock } from '../../DiscountBlock/DiscountBlock';
import { PackagesSlide } from '../PackagesSlide/PackagesSlide';
import {
  Package,
  Product,
  fetchPackage,
  getPackageIsLoading,
  setSelectedProduct,
} from '@/entities/Package';
import { PackagesSkeleton } from '@/features/PackagesSkeleton';
import { TabsPackages } from '@/features/TabsPackages';
import { getRoutePaymentStep1 } from '@/shared/const/router';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { useAppNavigation } from '@/shared/lib/hooks/useAppNavigation/useAppNavigation';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { useObserver, useSimpleObserver } from '@/shared/lib/hooks/useObserver';
import { useQueryParams } from '@/shared/lib/hooks/useQueryParams';
import { Flex, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

interface IPackagesBlockProps {
  className?: string;
  toShowSecondMessage: Dispatch<SetStateAction<boolean>>;
}

export const PackagesBlock: FC<IPackagesBlockProps> = memo(props => {
  const { className, toShowSecondMessage } = props;
  const t = useTranslations();
  const { push } = useAppNavigation();
  const dispatch = useAppDispatch();
  const searchParams = useQueryParams();

  const isLoading = useAppSelector(getPackageIsLoading);

  const { isDesktop, isMobile } = useDetectDevice();
  const blockRef = useRef<null | HTMLDivElement>(null);
  const blockId = 'pricingBlock';

  useObserver({
    handler: showCurrentBlock,
    id: `header-${blockId}`,
    ref: blockRef,
    rootMargin: '120px',
    threshold: 1,
  });
  useSimpleObserver(blockRef, toShowSecondMessage);

  // tabs
  const [selectedPackageLocal, setSelectedPackageLocal] = useState<Package>();
  const [allPackages, setAllPackages] = useState<Package[]>([]);

  const redirectToPayment = (product: Product) => {
    if (selectedPackageLocal) dispatch(setSelectedProduct(product));
    push({
      newParams: { productId: product.id },
      path: getRoutePaymentStep1(),
    });
  };

  const changePackage = (namePackage: Package) =>
    setSelectedPackageLocal(namePackage);

  const size = useMemo(() => (isMobile ? 12 : 25), [isMobile]);

  const subArray = useMemo(
    () =>
      selectedPackageLocal
        ? [selectedPackageLocal?.tradeInstruments.slice(0, size)]
        : [],
    [size, selectedPackageLocal],
  );

  const rightAlign = useMemo(
    () => (isDesktop ? 'start' : 'center'),
    [isDesktop],
  );
  const rightDirection = useMemo(
    () => (isDesktop ? 'row' : 'column'),
    [isDesktop],
  );

  const getPackages = useCallback(async () => {
    const response = await dispatch(fetchPackage(searchParams.ref));

    if (response.meta.requestStatus === 'fulfilled') {
      const data = response.payload as unknown as Package[];
      setAllPackages(data);
      setSelectedPackageLocal(data[0]);
    }
  }, [dispatch, searchParams.ref]);

  useEffect(() => {
    getPackages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VStack
      ref={blockRef}
      max
      id={blockId}
      align='center'
      justify='end'
      className={classNames(cls['packages-block'], {}, [className])}
    >
      <Text as='h2' variant='h2'>
        {t('Plans')}
      </Text>
      {isLoading ? (
        <PackagesSkeleton />
      ) : (
        <>
          <TabsPackages
            allPackages={allPackages}
            setSelectPackage={changePackage}
          />
          <DiscountBlock />
          <Flex
            className={cls['main-block']}
            align={rightAlign}
            direction={rightDirection}
          >
            <PackagesSlide
              item={subArray}
              selectedPackageLocal={selectedPackageLocal}
            />
            <PackageAside
              redirectToPayment={redirectToPayment}
              selectedPackageLocal={selectedPackageLocal}
            />
          </Flex>
        </>
      )}
    </VStack>
  );
});
