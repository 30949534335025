import { FC, memo, useMemo, useRef } from 'react';
import { ImageSide } from './ImageSide/ImageSide';
import { TextSide } from './TextSide/TextSide';
import cls from './screenerBlock.module.css';
import { showCurrentBlock } from '../../../model/lib/showCurrentBlock';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { useObserver } from '@/shared/lib/hooks/useObserver';
import { GStack } from '@/shared/ui/Stack';

interface IScreenerBlock {
  className?: string;
}

export const ScreenerBlock: FC<IScreenerBlock> = memo(props => {
  const { className } = props;
  const { isDesktop, isTabletHorizontal } = useDetectDevice();
  const blockRef = useRef<null | HTMLDivElement>(null);
  const blockId = 'screenerBlock';

  useObserver({
    handler: showCurrentBlock,
    id: `header-${blockId}`,
    ref: blockRef,
    rootMargin: '120px',
    threshold: 1,
  });

  const gtc = useMemo(
    () => (isDesktop || isTabletHorizontal ? '1fr 1fr' : '1fr'),
    [isDesktop, isTabletHorizontal],
  );

  const gapC = useMemo(
    () => (isDesktop || isTabletHorizontal ? '4-x-l' : '0'),
    [isDesktop, isTabletHorizontal],
  );

  const gapR = useMemo(
    () => (isDesktop || isTabletHorizontal ? '0' : '2-x-l'),
    [isDesktop, isTabletHorizontal],
  );

  return (
    <GStack
      ref={blockRef}
      max
      id={blockId}
      gapC={gapC}
      gapR={gapR}
      gtc={gtc}
      className={classNames(cls['screener-block'], {}, [className])}
    >
      <TextSide />
      <ImageSide />
    </GStack>
  );
});
