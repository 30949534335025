import { FC, memo, useRef, useState } from 'react';
import { AnimatedLine } from './AnimatedLine';
import { LeftSection } from './LeftSection/LeftSection';
import { RightSection } from './RightSection/RightSection';
import cls from './advantagesBlock.module.css';
import { showCurrentBlock } from '../../../model/lib/showCurrentBlock';
import SphereImage from '@/shared/assets/images/HeroImage_Tablet.png';
import SphereVideo from '@/shared/assets/videos/sphere/Jerold_animation_1080x1080.mp4';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { useObserver } from '@/shared/lib/hooks/useObserver';
import { AppImage } from '@/shared/ui/AppImage';
import { Video } from '@/shared/ui/Video/Video';

interface IAdvantagesBlockProps {
  className?: string;
}

export const AdvantagesBlock: FC<IAdvantagesBlockProps> = memo(props => {
  const { className } = props;
  const [onVisible, setOnVisible] = useState(false);
  const blockRef = useRef<null | HTMLDivElement>(null);
  const { isMobile } = useDetectDevice();
  const blockId = 'advantagesBlock';

  useObserver({
    handler: showCurrentBlock,
    id: `header-${blockId}`,
    ref: blockRef,
    rootMargin: '360px',
    setState: setOnVisible,
    threshold: 1,
  });

  return (
    <div
      ref={blockRef}
      key='AdvantagesBlock-grid'
      id={blockId}
      className={classNames(cls['advantages-block'], {}, [className])}
    >
      <LeftSection />
      <AnimatedLine onVisible={onVisible} />
      {isMobile ? (
        <AppImage src={SphereImage} alt='' className={cls.sphere} />
      ) : (
        <Video width={350} height={331} src={SphereVideo} />
      )}
      <RightSection />
    </div>
  );
});
