import { FC, createElement, memo } from 'react';
import cls from './advantagesBlock.module.css';
import { FatLineSVG, ThinLineSVG } from '@/shared/assets/svg/Advantages';
import { HStack } from '@/shared/ui/Stack';

interface IAnimatedLineProps {
  onVisible?: boolean;
}

export const AnimatedLine: FC<IAnimatedLineProps> = memo(props => {
  const { onVisible } = props;

  return (
    <HStack
      max
      align='center'
      justify='between'
      className={cls['line-wrapper']}
    >
      {!!onVisible && (
        <>
          <div className={cls.line} />
          <HStack>
            {createElement(ThinLineSVG, {
              color: '#004E5E',
              height: 57,
              width: 5,
            })}
            {createElement(FatLineSVG, {
              color: '#02BBDF',
              height: 93,
              width: 12,
            })}
          </HStack>
        </>
      )}
    </HStack>
  );
});
