import { useTranslations } from 'next-intl';
import { FC, memo, useMemo } from 'react';
import cls from './textSide.module.css';
import { CheckBold } from '@/shared/assets/svg/Common';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { Button } from '@/shared/ui/Buttons';
import { Icon } from '@/shared/ui/Icon';
import { HStack, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

interface ITextSide {
  className?: string;
}

export const TextSide: FC<ITextSide> = memo(props => {
  const { className } = props;
  const { isMobile } = useDetectDevice();
  const t = useTranslations();

  const handleClickScroll = () => {
    const block = document.getElementById('pricingBlock');
    block?.scrollIntoView({ behavior: 'smooth' });
  };

  const checkedText = useMemo(
    () => [
      {
        icon: CheckBold,
        text: t(
          'Up-to-date signals about price levels in the Telegram channel',
        ),
      },
      {
        icon: CheckBold,
        text: t(
          'A list of instruments with levels and directions that you will receive throughout the trading day',
        ),
      },
    ],
    [t],
  );

  const numberedText = useMemo(
    () => [
      {
        span: 1,
        text: t('Get trading signals'),
      },
      {
        span: 2,
        text: t('Set alerts'),
      },
      {
        span: 3,
        text: t('Wait for the entry point when the alert is triggered'),
      },
    ],
    [t],
  );

  const iconSize = useMemo(() => (isMobile ? '16' : '24'), [isMobile]);

  return (
    <VStack className={classNames(cls['text-side'], {}, [className])}>
      <Text variant='h2' as='h2'>
        {'Screener'}
      </Text>
      <Text className={cls.subtitle} variant='body-m'>
        {t(
          'Service for both day traders and swing traders that identifies key levels and provides direction for trading',
        )}
      </Text>
      <VStack max className={cls['checked-text']}>
        {checkedText.map(str => (
          <HStack key={str.text} max gap='s'>
            <Icon
              Svg={str.icon}
              width={iconSize}
              height={iconSize}
              color='#00C297'
            />
            <Text variant='body-m'>{str.text}</Text>
          </HStack>
        ))}
      </VStack>
      <Text variant='body-l-medium' className={cls['numbered-text-title']}>
        {t('Your trading now consists of three actions')}
      </Text>
      <VStack max>
        {numberedText.map(str => (
          <HStack key={str.span} className={cls['numbered-text']}>
            <Text variant='numbers-x-s-bold'>{str.span}</Text>
            <Text variant='body-m'>{str.text}</Text>
          </HStack>
        ))}
      </VStack>
      <Button
        className={cls['subscribe-button']}
        text={t('Subscribe')}
        typeButton='accent'
        fullWidth={isMobile}
        onClick={handleClickScroll}
      />
    </VStack>
  );
});
