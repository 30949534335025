import { useTranslations } from 'next-intl';
import { FC, memo, useRef, useState } from 'react';
import { DesktopRoadmapStepper } from './DesktopRoadmapStepper/DesktopRoadmapStepper';
import { MobileRoadmapStepper } from './MobileRoadmapStepper/MobileRoadmapStepper';
import cls from './roadmapBlock.module.css';
import { itemsRoadmap } from '../../../model/consts/itemsRoadmap';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { useSimpleObserver } from '@/shared/lib/hooks/useObserver';
import { Pictures } from '@/shared/ui/Pictures';
import { Flex, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

interface IRoadmapBlockProps {
  className?: string;
}

export const RoadmapBlock: FC<IRoadmapBlockProps> = memo(props => {
  const { className } = props;
  const t = useTranslations();
  const [onVisible, setOnVisible] = useState(false);
  const blockRef = useRef<null | HTMLDivElement>(null);
  const { isDesktop } = useDetectDevice();

  useSimpleObserver(blockRef, setOnVisible);

  const allItems = itemsRoadmap(t);

  return (
    <VStack
      ref={blockRef}
      max
      align='center'
      gap='2-x-l'
      className={classNames(cls['roadmap-block'], {}, [className])}
    >
      <Text as='h2' variant='h2'>
        {t('Roadmap')}
      </Text>
      {isDesktop
        ? !!onVisible && <DesktopRoadmapStepper />
        : !!onVisible && <MobileRoadmapStepper />}
      <Flex className={cls.images} direction={isDesktop ? 'row' : 'column'}>
        {allItems.map(item => (
          <Pictures
            key={item.alt}
            srcAvif={item.avif}
            srcWebp={item.webp}
            srcImage={item.png}
            alt={item.alt}
          />
        ))}
      </Flex>
    </VStack>
  );
});
