import Image from 'next/image';
import { FC, memo } from 'react';
import cls from './triangleNew.module.css';
import { HStack } from '../../Stack';
import { Text } from '../../Text';
import { NotoFirePng } from '@/shared/assets/svg/ProductsBlock';
import { classNames } from '@/shared/lib/classNames/classNames';

interface ITriangleNew {
  className?: string;
}

export const TriangleNew: FC<ITriangleNew> = memo(props => {
  const { className } = props;

  return (
    <HStack className={classNames(cls['triangle-new'], {}, [className])}>
      <HStack>
        <Text variant='h4' as='h4'>
          {'NEW'}
        </Text>
        <Image src={NotoFirePng} alt='' width={28} height={28} />
      </HStack>
    </HStack>
  );
});
