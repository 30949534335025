import { useTranslations } from 'next-intl';
import { FC, memo, useMemo } from 'react';
import cls from './footer.module.css';
import { getGeolocationUser } from '@/entities/User';
import {
  Facebook2OutlinedSVG,
  InstagramFilledSVG,
  YoutubeFilledSVG,
  TelegramSVG,
} from '@/shared/assets/svg/SocialNetworks';
import currentYear from '@/shared/const/nowYear';
import {
  getRoutePrivacyPolicy,
  getRouteTermsOfUse,
} from '@/shared/const/router';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { Icon } from '@/shared/ui/Icon';
import { Links } from '@/shared/ui/Links/Links';
import { Flex, HStack, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';
import { TextLink } from '@/shared/ui/TextLink/TextLink';

interface IFooterProps {
  className?: string;
}

export const Footer: FC<IFooterProps> = memo(props => {
  const { className } = props;
  const t = useTranslations();
  const { isMobile } = useDetectDevice();

  const country = useAppSelector(getGeolocationUser);

  const itemsSocials = [
    { icon: TelegramSVG, path: 'https://t.me/+oF4TUcoXW3YzMmQy' },
    {
      icon: Facebook2OutlinedSVG,
      path: 'https://www.facebook.com/jerold.trading',
    },
    { icon: YoutubeFilledSVG, path: 'https://www.youtube.com/@jerold.trading' },
    {
      icon: InstagramFilledSVG,
      path: 'https://www.instagram.com/jerold.trading/',
    },
  ];

  const isRuReg = useMemo(
    () => country === 'RU' || country === 'BY',
    [country],
  );

  const validAddress = useMemo(
    () =>
      isRuReg
        ? {
            address: t('Russia address'),
            name: t('Company Name rus'),
            number: `${t('OGRN')} ${t('Code rus')}`,
          }
        : {
            address: t('Toronto address'),
            name: t('Company Name JEROLD'),
            number: t('Code other'),
          },
    [isRuReg, t],
  );

  return (
    <VStack max className={classNames(cls.footer, {}, [className])} gap='x-l'>
      <Flex
        max
        direction={isMobile ? 'column' : 'row'}
        gap='2-x-l'
        align='start'
      >
        <VStack gap='s' className={cls.block}>
          <Text variant='h5' as='h5'>
            {t('Company information')}
          </Text>
          <VStack gap='x-s'>
            <Text variant='body-m'>{validAddress.name}</Text>
            <Text variant='body-m'>{validAddress.address}</Text>
            <Text variant='body-m'>{validAddress.number}</Text>
          </VStack>
          <Links href='mailto: support@jerold.io'>
            <TextLink variant='accent'>{'support@jerold.io'}</TextLink>
          </Links>
        </VStack>
        <VStack gap='s' className={cls.block}>
          <Text variant='h5' as='h5'>
            {t('Agreements')}
          </Text>
          <Links href={getRouteTermsOfUse()}>
            <TextLink>{t('Terms of use')}</TextLink>
          </Links>
          <Links href={getRoutePrivacyPolicy()}>
            <TextLink>{t('Privacy Policy')}</TextLink>
          </Links>
        </VStack>
      </Flex>
      <div className={cls.border} />
      <Flex
        max
        gap='s'
        justify='between'
        direction={isMobile ? 'column-reverse' : 'row'}
      >
        <Text variant='body-m'>{`© 2022-${currentYear} Jerold`}</Text>
        <HStack gap='s' className={cls.socials}>
          {itemsSocials.map(item => (
            <Links
              key={item.path}
              target='_blank'
              rel='noreferrer'
              href={item.path}
            >
              <Icon
                Svg={item.icon}
                className={cls.icons}
                width={24}
                height={24}
              />
            </Links>
          ))}
        </HStack>
      </Flex>
    </VStack>
  );
});
