import { useTranslations } from 'next-intl';
import { FC, memo } from 'react';
import cls from './mobileRoadmapStepper.module.css';
import { ActiveOldSVG, DotOldSVG } from '@/shared/assets/svg/Stepper';
import { classNames } from '@/shared/lib/classNames/classNames';
import { Icon } from '@/shared/ui/Icon';
import { HStack, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

export interface IStepperProps {
  className?: string;
}

export const MobileRoadmapStepper: FC<IStepperProps> = memo(props => {
  const { className } = props;
  const t = useTranslations();

  const steps = ['Q1.2023', 'Q3.2023', 'Q4.2023', '2024', ''];

  const currentIcon = [
    ActiveOldSVG,
    ActiveOldSVG,
    ActiveOldSVG,
    ActiveOldSVG,
    DotOldSVG,
  ];

  const currentIconColor = [
    '#02BBDF',
    '#02BBDF',
    '#02BBDF',
    '#333333',
    '#00829C',
  ];

  return (
    <VStack className={classNames(cls.stepper, {}, [className])}>
      <VStack justify='between' className={cls.inner}>
        {steps.map((item, index) => (
          <HStack key={`key-${item + index}`} className={cls.wrapper}>
            <div className={cls['block-text']}>
              <Text variant='body-m'>{item}</Text>
            </div>
            <HStack align='center' justify='center' className={cls.right}>
              <Icon
                Svg={currentIcon[index]}
                width={17}
                height={17}
                color={currentIconColor[index]}
              />
            </HStack>
          </HStack>
        ))}
      </VStack>
      <div className={cls.line1} />
      <div className={cls.line2} />
    </VStack>
  );
});
