import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useLocale, useTranslations } from 'next-intl';
import { FC, memo, useMemo, useRef } from 'react';
import cls from './reviewsBlock.module.css';
import { arrReviews } from '../../../model/consts/reviews';
import { showCurrentBlock } from '../../../model/lib/showCurrentBlock';
import { reviewsBlockSliderOptions } from '../model/sliderOptions';
import {
  InstagramOutlined,
  Telegram24p,
} from '@/shared/assets/svg/SocialNetworks';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useObserver } from '@/shared/lib/hooks/useObserver';
import { Icon } from '@/shared/ui/Icon';
import { Links } from '@/shared/ui/Links/Links';
import { Pictures } from '@/shared/ui/Pictures';
import { HStack, VStack } from '@/shared/ui/Stack';
import './splide.css';
import { Text } from '@/shared/ui/Text';

interface IReviewsBlockProps {
  className?: string;
}

export const ReviewsBlock: FC<IReviewsBlockProps> = memo(props => {
  const { className } = props;
  const t = useTranslations();
  const language = useLocale();
  const blockRef = useRef<null | HTMLDivElement>(null);
  const blockId = 'reviewsBlock';

  useObserver({
    handler: showCurrentBlock,
    id: `header-${blockId}`,
    ref: blockRef,
    rootMargin: '120px',
    threshold: 1,
  });

  const array = useMemo(() => arrReviews(language), [language]);

  return (
    <VStack
      ref={blockRef}
      className={classNames(cls['reviews-block'], {}, [className])}
      align='center'
      id={blockId}
      justify='end'
    >
      <Text as='h2' variant='h2' align='center'>
        {t('What do users say about Jerold')}
      </Text>
      <HStack className={cls.buttons} gap='m'>
        <Links
          href='https://t.me/+oF4TUcoXW3YzMmQy'
          target='_blank'
          rel='noreferrer'
        >
          <HStack gap='s'>
            <Icon Svg={Telegram24p} color='#4CFEC9' width={24} height={24} />
            <Text variant='body-l'>{'Telegram'}</Text>
          </HStack>
        </Links>
        <Links
          href='https://www.instagram.com/jerold.trading/'
          target='_blank'
          rel='noreferrer'
        >
          <HStack gap='s'>
            <Icon
              Svg={InstagramOutlined}
              color='#4CFEC9'
              width={24}
              height={24}
            />
            <Text variant='body-l'>{t('Instagram')}</Text>
          </HStack>
        </Links>
      </HStack>
      <Splide
        className={classNames(`${cls.slider} reviews-block-slider`)}
        options={reviewsBlockSliderOptions}
      >
        {array.map((review, index) => (
          <SplideSlide key={index}>
            <Pictures
              srcAvif={review.avif}
              srcWebp={review.webp}
              srcImage={review.png}
            />
          </SplideSlide>
        ))}
      </Splide>
    </VStack>
  );
});
