import { IItemRoadmap } from '../types/types';
import CryptoAvifDesktop from '@/shared/assets/images/Desktop/Companies/Crypto/Crypto_332x497.avif';
import CryptoPngDesktop from '@/shared/assets/images/Desktop/Companies/Crypto/Crypto_332x497.png';
import CryptoWebpDesktop from '@/shared/assets/images/Desktop/Companies/Crypto/Crypto_332x497.webp';
import ForexAvifDesktop from '@/shared/assets/images/Desktop/Companies/Forex(CFD)/Forex(CFD)_332x497.avif';
import ForexPngDesktop from '@/shared/assets/images/Desktop/Companies/Forex(CFD)/Forex(CFD)_332x497.png';
import ForexWebpDesktop from '@/shared/assets/images/Desktop/Companies/Forex(CFD)/Forex(CFD)_332x497.webp';
import FuturesAvifDesktop from '@/shared/assets/images/Desktop/Companies/Futures/Futures_332x497.avif';
import FuturesPngDesktop from '@/shared/assets/images/Desktop/Companies/Futures/Futures_332x497.png';
import FuturesWebpDesktop from '@/shared/assets/images/Desktop/Companies/Futures/Futures_332x497.webp';
import StocksAvifDesktop from '@/shared/assets/images/Desktop/Companies/Stocks/Stocks_332x497.avif';
import StocksPngDesktop from '@/shared/assets/images/Desktop/Companies/Stocks/Stocks_332x497.png';
import StocksWebpDesktop from '@/shared/assets/images/Desktop/Companies/Stocks/Stocks_332x497.webp';
import CryptoAvifMobile from '@/shared/assets/images/Mobile/Companies/Crypto/Crypto_311x497.avif';
import CryptoPngMobile from '@/shared/assets/images/Mobile/Companies/Crypto/Crypto_311x497.png';
import CryptoWebpMobile from '@/shared/assets/images/Mobile/Companies/Crypto/Crypto_311x497.webp';
import ForexAvifMobile from '@/shared/assets/images/Mobile/Companies/Forex(CFD)/Forex(CFD)_311x497.avif';
import ForexPngMobile from '@/shared/assets/images/Mobile/Companies/Forex(CFD)/Forex(CFD)_311x497.png';
import ForexWebpMobile from '@/shared/assets/images/Mobile/Companies/Forex(CFD)/Forex(CFD)_311x497.webp';
import FuturesAvifMobile from '@/shared/assets/images/Mobile/Companies/Futures/Futures_311x497.avif';
import FuturesPngMobile from '@/shared/assets/images/Mobile/Companies/Futures/Futures_311x497.png';
import FuturesWebpMobile from '@/shared/assets/images/Mobile/Companies/Futures/Futures_311x497.webp';
import StocksAvifMobile from '@/shared/assets/images/Mobile/Companies/Stocks/Stocks_311x497.avif';
import StocksPngMobile from '@/shared/assets/images/Mobile/Companies/Stocks/Stocks_311x497.png';
import StocksWebpMobile from '@/shared/assets/images/Mobile/Companies/Stocks/Stocks_311x497.webp';
import CryptoAvifTablet from '@/shared/assets/images/Tablet/Companies/Crypto/Crypto_604x341.avif';
import CryptoPngTablet from '@/shared/assets/images/Tablet/Companies/Crypto/Crypto_604x341.png';
import CryptoWebpTablet from '@/shared/assets/images/Tablet/Companies/Crypto/Crypto_604x341.webp';
import ForexAvifTablet from '@/shared/assets/images/Tablet/Companies/Forex(CFD)/Forex(CFD)_604x341.avif';
import ForexPngTablet from '@/shared/assets/images/Tablet/Companies/Forex(CFD)/Forex(CFD)_604x341.png';
import ForexWebpTablet from '@/shared/assets/images/Tablet/Companies/Forex(CFD)/Forex(CFD)_604x341.webp';
import FuturesAvifTablet from '@/shared/assets/images/Tablet/Companies/Futures/Futures_604x341.avif';
import FuturesPngTablet from '@/shared/assets/images/Tablet/Companies/Futures/Futures_604x341.png';
import FuturesWebpTablet from '@/shared/assets/images/Tablet/Companies/Futures/Futures_604x341.webp';
import StocksAvifTablet from '@/shared/assets/images/Tablet/Companies/Stocks/Stocks_604x341.avif';
import StocksPngTablet from '@/shared/assets/images/Tablet/Companies/Stocks/Stocks_604x341.png';
import StocksWebpTablet from '@/shared/assets/images/Tablet/Companies/Stocks/Stocks_604x341.webp';
import { TFunction } from '@/shared/types/next-intl';

export const itemsRoadmap = (t: TFunction): IItemRoadmap[] => [
  {
    alt: 'Forex (CFD)',
    avif: [
      { media: 1440, src: ForexAvifDesktop },
      { media: 768, src: ForexAvifTablet },
      { media: 320, src: ForexAvifMobile },
    ],
    png: [
      { media: 1440, src: ForexPngDesktop },
      { media: 768, src: ForexPngTablet },
      { media: 320, src: ForexPngMobile },
    ],
    webp: [
      { media: 1440, src: ForexWebpDesktop },
      { media: 768, src: ForexWebpTablet },
      { media: 320, src: ForexWebpMobile },
    ],
  },
  {
    alt: 'Crypto',
    avif: [
      { media: 1440, src: CryptoAvifDesktop },
      { media: 768, src: CryptoAvifTablet },
      { media: 320, src: CryptoAvifMobile },
    ],
    png: [
      { media: 1440, src: CryptoPngDesktop },
      { media: 768, src: CryptoPngTablet },
      { media: 320, src: CryptoPngMobile },
    ],
    webp: [
      { media: 1440, src: CryptoWebpDesktop },
      { media: 768, src: CryptoWebpTablet },
      { media: 320, src: CryptoWebpMobile },
    ],
  },
  {
    alt: 'Stocks',
    avif: [
      { media: 1440, src: StocksAvifDesktop },
      { media: 768, src: StocksAvifTablet },
      { media: 320, src: StocksAvifMobile },
    ],
    png: [
      { media: 1440, src: StocksPngDesktop },
      { media: 768, src: StocksPngTablet },
      { media: 320, src: StocksPngMobile },
    ],
    webp: [
      { media: 1440, src: StocksWebpDesktop },
      { media: 768, src: StocksWebpTablet },
      { media: 320, src: StocksWebpMobile },
    ],
  },
  {
    alt: 'Futures',
    avif: [
      { media: 1440, src: FuturesAvifDesktop },
      { media: 768, src: FuturesAvifTablet },
      { media: 320, src: FuturesAvifMobile },
    ],
    png: [
      { media: 1440, src: FuturesPngDesktop },
      { media: 768, src: FuturesPngTablet },
      { media: 320, src: FuturesPngMobile },
    ],
    webp: [
      { media: 1440, src: FuturesWebpDesktop },
      { media: 768, src: FuturesWebpTablet },
      { media: 320, src: FuturesWebpMobile },
    ],
  },
];
