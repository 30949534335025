import { useTranslations } from 'next-intl';
import { FC, memo } from 'react';
import cls from './desktopRoadmapStepper.module.css';
import {
  ActiveOldSVG,
  InactiveOldSVG,
  DotOldSVG,
} from '@/shared/assets/svg/Stepper';
import { classNames } from '@/shared/lib/classNames/classNames';
import { Icon } from '@/shared/ui/Icon';
import { HStack, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

export interface IStepperProps {
  className?: string;
}

export const DesktopRoadmapStepper: FC<IStepperProps> = memo(props => {
  const { className } = props;
  const t = useTranslations();

  const steps = ['', 'Q1.2023', 'Q3.2023', 'Q4.2023', '2024', ''];

  const currentIcon = [
    InactiveOldSVG,
    ActiveOldSVG,
    ActiveOldSVG,
    ActiveOldSVG,
    InactiveOldSVG,
    DotOldSVG,
  ];

  const currentIconColor = [
    '#333333',
    '#02BBDF',
    '#02BBDF',
    '#02BBDF',
    '#333333',
    '#00829C',
  ];

  return (
    <VStack
      max
      align='stretch'
      className={classNames(cls.stepper, {}, [className])}
    >
      <HStack className={cls.inner}>
        {steps.map((item, index) => (
          <VStack
            key={`key-${item + index}`}
            align='center'
            justify='between'
            className={cls.wrapper}
          >
            <HStack max justify='center' className={cls['block-text']}>
              <Text variant='body-m'>{item}</Text>
            </HStack>
            <HStack align='center' justify='center' className={cls.right}>
              <Icon
                Svg={currentIcon[index]}
                width={17}
                height={17}
                color={currentIconColor[index]}
              />
            </HStack>
          </VStack>
        ))}
      </HStack>
      <div className={cls.line1} />
      <div className={cls.line2} />
    </VStack>
  );
});
