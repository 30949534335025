import { FC, memo, useCallback, useEffect, useState } from 'react';
import cls from './itemResultBlock.module.css';
import { classNames } from '@/shared/lib/classNames/classNames';
import { HStack, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

interface IItemResultBlockProps {
  className?: string;
  onVisible?: boolean;
  item: {
    subtitle: string;
    title?: string;
    title2?: string;
    maxCount: number;
  };
}

export const ItemResultBlock: FC<IItemResultBlockProps> = memo(props => {
  const {
    className,
    onVisible,
    item: { subtitle, title, title2, maxCount },
  } = props;

  const [number, setNumber] = useState(0);

  let step = Math.round(maxCount / 100);
  if (step < 1) step = 1;

  const runNumber = useCallback(() => {
    if (number === maxCount) return;

    let num = number + step;
    if (num > maxCount) num = maxCount;
    setNumber(num);
  }, [maxCount, number, step]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (onVisible) timer = setTimeout(runNumber, 20);

    return () => {
      clearTimeout(timer);
    };
  }, [number, onVisible, runNumber]);

  return (
    <VStack
      gap='x-l'
      className={classNames(cls['item-result-block'], {}, [className])}
    >
      <HStack>
        {!!title && <h4 style={{ marginRight: 25 }}>{title}</h4>}
        {!!onVisible && <h4>{number}</h4>}
        <h4>{title2}</h4>
      </HStack>
      <Text variant='body-m'>{subtitle}</Text>
    </VStack>
  );
});
