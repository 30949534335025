import { useTranslations } from 'next-intl';
import { FC, memo, useMemo } from 'react';
import cls from '../old/PackageBlock/packagesBlock.module.css';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { Flex, HStack, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

export const DiscountBlock: FC = memo(() => {
  const { isMobile, isTablet } = useDetectDevice();
  const t = useTranslations();

  const directionDiscount = useMemo(
    () => (isMobile ? 'column' : 'row'),
    [isMobile],
  );

  const gapDiscount = useMemo(() => (isMobile ? 'm' : 'x-l'), [isMobile]);
  const discountAlign = useMemo(() => (isMobile ? 'start' : 'end'), [isMobile]);
  const fontSize = useMemo(() => {
    if (isMobile) return 'numbers-m-bold';
    if (isTablet) return 'numbers-l-bold';
    return 'numbers-x-l';
  }, [isMobile, isTablet]);

  return (
    <Flex
      max
      className={cls['discount-widget']}
      justify='between'
      gap={gapDiscount}
      direction={directionDiscount}
    >
      <Text variant='subheading'>{t('Try trading signals in 30 days')}</Text>
      <HStack gap='m'>
        <Text variant={fontSize} className={cls.red}>
          {'-50%'}
        </Text>
        <VStack gap='x-x-s' align={discountAlign}>
          <Text variant='subheading-bold-uppercase' className={cls.discount}>
            {t('Discount')}
          </Text>
          <Text variant='body-m'>{t('on the 1st month')}</Text>
        </VStack>
      </HStack>
    </Flex>
  );
});
