import { useLocale, useTranslations } from 'next-intl';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import cls from './screenerPricePackagesBlock.module.css';
import {
  Package,
  Product,
  getAllPromoScreenerPackages,
  getAllPromoSignalsPackages,
  getAllScreenerPackages,
  getAllSignalsPackages,
  setSelectedProduct,
  getAllCopytradingPackages,
} from '@/entities/Package';
import { DotOutline } from '@/shared/assets/svg/Common';
import { ArrowRightOutlinedSVG } from '@/shared/assets/svg/Main/Arrows';
import { getRoutePaymentStep1 } from '@/shared/const/router';
import { classNames } from '@/shared/lib/classNames/classNames';
import { replaceZeroPrice } from '@/shared/lib/helpers/replaceZeroPrice';
import { selectPeriodPackages } from '@/shared/lib/helpers/selectPeriodPackages/selectPeriodPackages';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { useAppNavigation } from '@/shared/lib/hooks/useAppNavigation/useAppNavigation';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { Button, SwitchButton } from '@/shared/ui/Buttons';
import { Icon } from '@/shared/ui/Icon';
import { OldOrNewPrice } from '@/shared/ui/OldOrNewPrice';
import { Flex, HStack, VStack } from '@/shared/ui/Stack';
import { Tabs } from '@/shared/ui/Tabs';
import { Text } from '@/shared/ui/Text';
import { InstrumentsPackagesModal } from '@/widgets/Modals';

interface IScreenerPricePackagesBlockProps {
  className?: string;
  typePackages: 'screener' | 'signals' | 'copytrading';
  isPartner: boolean;
}

export const ScreenerPricePackagesBlock: FC<IScreenerPricePackagesBlockProps> =
  memo(props => {
    const { className, typePackages, isPartner } = props;

    const t = useTranslations();
    const dispatch = useAppDispatch();
    const { push } = useAppNavigation();
    const { isDesktop, isMobile } = useDetectDevice();
    const lang = useLocale();

    const allCopytradingPackages = useAppSelector(getAllCopytradingPackages);
    const allScreenerPackages = useAppSelector(getAllScreenerPackages);
    const allSignalsPackages = useAppSelector(getAllSignalsPackages);
    const allPromoSignalsPackages = useAppSelector(getAllPromoSignalsPackages);
    const allPromoScreenerPackages = useAppSelector(
      getAllPromoScreenerPackages,
    );

    const [selectedPackageLocal, setSelectedPackageLocal] =
      useState<Package | null>(null);
    const [selectedProductLocal, setSelectedProductLocal] =
      useState<Product | null>(null);

    const [openModal, setOpenModal] = useState(false);
    const closeModal = useCallback(() => setOpenModal(!openModal), [openModal]);

    const [isNewUser, setIsNewUser] = useState(typePackages !== 'screener');

    const preparePrice = useCallback(
      (price: string) =>
        lang === 'ru'
          ? `${replaceZeroPrice(price)}$`
          : `$${replaceZeroPrice(price)}`,
      [lang],
    );

    const amountInstruments = useMemo(() => {
      if (selectedProductLocal?.marketName === 'Forex') return '40';
      if (selectedProductLocal?.marketName === 'Stocks') return '500+';
      return '1500+';
    }, [selectedProductLocal]);

    const changeSwitchButton = (value: boolean) => {
      setIsNewUser(value);
      if (selectedPackageLocal) {
        const product =
          typePackages === 'screener'
            ? selectedPackageLocal?.products[0]
            : selectedPackageLocal?.products[value ? 0 : 1];

        setSelectedProductLocal(product);
      }
    };

    const changeTab = (pack: Package) => {
      setSelectedPackageLocal(pack);
      const product =
        typePackages === 'screener'
          ? pack?.products[0]
          : pack?.products[isNewUser ? 0 : 1];

      setSelectedProductLocal(product);
    };

    const redirectToPayment = () => {
      if (selectedProductLocal) {
        dispatch(setSelectedProduct(selectedProductLocal));
        push({
          newParams: { productId: selectedProductLocal.id },
          path: getRoutePaymentStep1(),
        });
      }
    };

    const allCurrentPackages = useMemo(() => {
      if (isPartner)
        return typePackages === 'screener'
          ? allPromoScreenerPackages
          : allPromoSignalsPackages;

      if (typePackages === 'screener') return allScreenerPackages;
      if (typePackages === 'signals') return allSignalsPackages;

      return allCopytradingPackages;
    }, [
      allPromoScreenerPackages,
      allPromoSignalsPackages,
      allCopytradingPackages,
      allScreenerPackages,
      allSignalsPackages,
      isPartner,
      typePackages,
    ]);

    useEffect(() => {
      setSelectedPackageLocal(allCurrentPackages[0]);
      setSelectedProductLocal(allCurrentPackages[0]?.products[0]);
    }, [allCurrentPackages]);

    useEffect(() => {
      setIsNewUser(typePackages !== 'screener');
    }, [typePackages]);

    const descriptionText = useMemo(
      () =>
        typePackages === 'signals' ? (
          <Text variant='body-s' className={cls['description-text']}>
            {`• ${t('The discount is valid only for new users during the first month of use')} ${preparePrice(selectedProductLocal?.oldPrice || selectedProductLocal?.price || '')}${selectedProductLocal?.duration && selectPeriodPackages(selectedProductLocal?.duration, t).replaceAll(' ', '')}`}
          </Text>
        ) : (
          !isMobile && <div style={{ height: '0' }} />
        ),
      [isMobile, selectedProductLocal, t, typePackages, preparePrice],
    );

    const gap = useMemo(() => (isMobile ? 'x-l' : '2-x-l'), [isMobile]);
    const direction = useMemo(() => (isMobile ? 'column' : 'row'), [isMobile]);
    const directionInstruments = useMemo(
      () => (isMobile ? 'row' : 'column'),
      [isMobile],
    );
    const align = useMemo(() => {
      if (typePackages === 'signals') return isMobile ? 'start' : 'center';
      return 'center';
    }, [isMobile, typePackages]);
    const sizeButton = useMemo(
      () => (isMobile ? 'small' : 'default'),
      [isMobile],
    );
    const sizePrice = useMemo(() => (isMobile ? 's' : 'm'), [isMobile]);
    const iconSize = useMemo(() => (isMobile ? '16' : '24'), [isMobile]);

    const copytradingText = useMemo(
      () => [
        {
          icon: DotOutline,
          text: t('You give 20% of the profit to Jerold'),
        },
        {
          icon: DotOutline,
          text: t('1000 is the minimum account size for copy trading'),
        },
        {
          icon: DotOutline,
          text: t('Copy trading is only available through Bybit'),
        },
      ],
      [t],
    );

    const fakeTab = useMemo(
      () =>
        selectedProductLocal?.type === 'copy-trading'
          ? ['Forex', 'Stocks']
          : null,
      [selectedProductLocal?.type],
    );

    const fontSize = useMemo(
      () => (isMobile ? 'numbers-s-bold' : 'numbers-m-bold'),
      [isMobile],
    );

    const showInstruments = useMemo(
      () =>
        typePackages === 'copytrading' ? null : (
          <Flex
            max
            direction={direction}
            align={align}
            justify='between'
            gap='x-s'
          >
            <Flex
              max={isMobile}
              direction={directionInstruments}
              gap='x-s'
              justify='between'
            >
              <Text variant='body-m' className={cls.text}>
                {t('Instruments')}
              </Text>
              <Text as='h6' variant={fontSize}>
                {amountInstruments}
              </Text>
            </Flex>
            <Button
              text={t('All instruments')}
              typeButton='link'
              img={ArrowRightOutlinedSVG}
              imgLocation='right'
              size='small'
              uppercase={false}
              onClick={closeModal}
            />
          </Flex>
        ),
      [
        align,
        amountInstruments,
        closeModal,
        direction,
        t,
        typePackages,
        isMobile,
        directionInstruments,
        fontSize,
      ],
    );

    const justifyPrice = useMemo(
      () => (typePackages === 'signals' ? 'between' : 'center'),
      [typePackages],
    );

    return (
      <VStack
        max={!isDesktop}
        className={classNames(cls['screener-price-packages-block'], {}, [
          className,
        ])}
      >
        <Tabs
          fullWidth
          typeTabs='screener'
          selectedTab={selectedPackageLocal as Package}
          items={allCurrentPackages}
          fakeTab={fakeTab}
          onChangeItem={changeTab}
        />
        <VStack max className={cls.wrapper} gap={gap}>
          <VStack max gap='l'>
            <VStack max gap='m' className={cls.top}>
              <Flex
                max
                direction={direction}
                className={cls['price-wrapper']}
                justify={justifyPrice}
                align={align}
                gap='s'
              >
                {!!selectedProductLocal && (
                  <OldOrNewPrice
                    newPrice={selectedProductLocal?.price}
                    period={selectedProductLocal?.duration}
                    size={sizePrice}
                    oldPrice={
                      isNewUser ? selectedProductLocal?.oldPrice : undefined
                    }
                  />
                )}
                {typePackages === 'signals' && (
                  <SwitchButton
                    size={sizeButton}
                    value={isNewUser}
                    id='checked'
                    setValue={changeSwitchButton}
                    text={t('New user')}
                  />
                )}
              </Flex>
            </VStack>
            {showInstruments}
          </VStack>
          {typePackages === 'copytrading' && (
            <VStack max className={cls['checked-text']} gap='s'>
              {copytradingText.map(str => (
                <HStack key={str.text} max gap='s'>
                  <Icon
                    Svg={str.icon}
                    width={iconSize}
                    height={iconSize}
                    color='#00C297'
                  />
                  <Text variant='body-m'>{str.text}</Text>
                </HStack>
              ))}
            </VStack>
          )}
          <Button
            fullWidth
            typeButton='accent'
            size={sizeButton}
            text={`${t('Subscribe for')} ${selectedProductLocal?.price && preparePrice(selectedProductLocal?.price)}`}
            onClick={redirectToPayment}
          />
        </VStack>
        {!!openModal && !!selectedPackageLocal && (
          <InstrumentsPackagesModal
            closeModal={closeModal}
            openModal={openModal}
            packageLocal={selectedPackageLocal}
          />
        )}
        {descriptionText}
      </VStack>
    );
  });
