import { useTranslations } from 'next-intl';
import { FC, memo, useMemo } from 'react';
import cls from './discountBlock.module.css';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { Margins } from '@/shared/types/ui';
import { HStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

interface IDiscountBlockProps {
  className?: string;
  type: 'big' | 'small';
}

export const DiscountBlock: FC<IDiscountBlockProps> = memo(props => {
  const { className, type } = props;
  const t = useTranslations();
  const { isTablet, isMobile, isDesktop } = useDetectDevice();

  const currentGapWrapper: Margins = useMemo(() => {
    if (type === 'big') {
      if (isMobile) return 's';
      return 'x-l';
    }
    if (isTablet) return 'm';
    if (isMobile) return 'x-s';
    return '4-x-l';
  }, [isMobile, isTablet, type]);

  const renderText = useMemo(() => {
    if (type === 'big') return null;
    if (type === 'small' && !isMobile)
      return <Text variant='body-m'>{t('SALE')}</Text>;

    return null;
  }, [isMobile, t, type]);

  return (
    <HStack
      gap={currentGapWrapper}
      className={classNames(
        cls['discount-block'],
        { [cls.small]: type === 'small' },
        [className],
      )}
    >
      <Text variant='body-l' className={cls.title}>
        {t('black friday')}
      </Text>
      <HStack className={cls.price} gap={isDesktop ? 'l' : 's'}>
        <Text variant='h1'>{'-40% '}</Text>
        {renderText}
      </HStack>
    </HStack>
  );
});
