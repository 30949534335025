import { Suspense, memo, useRef, useState, useEffect } from 'react';
import { AdvantagesBlock } from './AdvantagesBlock';
import { BrainBlock } from './BrainBlock';
import { CreatorsBlock } from './CreatorsBlock';
import { FinishBlock } from './FinishBlock';
import { ScreenerPriceBlock } from './PackagesBlock';
import { ResultBlock } from './ResultBlock';
import { ReviewsBlock } from './ReviewsBlock';
import { RoadmapBlock } from './RoadmapBlock';
import { ScreenerBlock } from './ScreenerBlock';
import { WelcomeBlock } from './WelcomeBlock';
import cls from './homePage.module.css';
import SphereImage from '@/shared/assets/images/HeroImage_Tablet.png';
import SphereVideo from '@/shared/assets/videos/sphere/Jerold_animation_1080x1080.mp4';
import { getFeatureFlags } from '@/shared/lib/features';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { AppImage } from '@/shared/ui/AppImage';
import { Spinner } from '@/shared/ui/Loaders';
import { Video } from '@/shared/ui/Video/Video';
import { CroBanner } from '@/widgets/CROBanner';
import { CookiesBanner, CookiesModal } from '@/widgets/CookiesConfirmation';
import { Header } from '@/widgets/Header';
import { Page } from '@/widgets/Page';
import '@splidejs/react-splide/css';
import { PromoBanner } from '@/widgets/PromoBanner';

export const HomePage = memo(() => {
  const refVideo = useRef<HTMLDivElement | null>(null);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const { isMobile } = useDetectDevice();
  const [openCookiesModal, setOpenCookiesModal] = useState(false);
  const [viewCookiesBanner, setViewCookiesBanner] = useState(true);
  const [viewCroBanner, setViewCroBanner] = useState(true);
  const [checkObserve, setCheckObserve] = useState(false);
  const [once, setOnce] = useState(false);

  const onCLoseCroBanner = () => setViewCroBanner(!viewCroBanner);

  const move = () => {
    const scrollPosition = window.scrollY;
    setTop(scrollPosition / 1.25);
    setLeft(scrollPosition / 2.3);
    if (refVideo.current) refVideo.current.style.top = `${scrollPosition}`;
  };

  const isBlackFriday = getFeatureFlags('isBlackFriday');

  const isConsentMode = localStorage.getItem('consentMode');

  useEffect(() => {
    checkObserve && setOnce(true);
    window.removeEventListener('scroll', move);
  }, [checkObserve]);

  return (
    <Page className={cls['home-page']} align='center'>
      <Header />
      {!!openCookiesModal && <div className={cls['cookies-modal-bg']} />}
      {isMobile ? (
        <div className={cls['image-wrapper']}>
          <AppImage src={SphereImage} alt='' />
        </div>
      ) : (
        <div ref={refVideo} className={cls['video-wrapper']}>
          <Video
            width={720}
            height={684}
            src={SphereVideo}
            left={left}
            top={top}
            position='absolute'
          />
        </div>
      )}
      <Suspense fallback={<Spinner align='center' />}>
        <WelcomeBlock />
        <BrainBlock />
        <ResultBlock />
        <AdvantagesBlock />
        {/* {process.env.NEXT_PUBLIC_COPYTRADING === 'disabled' ? ( */}
        {/*  <OldProductsBlock /> */}
        {/* ) : ( */}
        {/*  <ProductsBlock /> */}
        {/* )} */}
        <ScreenerBlock />
        <ScreenerPriceBlock toShowSecondMessage={setCheckObserve} />
        {/* <SignalsBlockNew /> */}
        <ReviewsBlock />
        <CreatorsBlock />
        <RoadmapBlock />
        <FinishBlock />
        {!!isBlackFriday && (
          <PromoBanner isConsentMode={!!viewCookiesBanner && !isConsentMode} />
        )}
        {!!viewCookiesBanner && !isConsentMode && (
          <CookiesBanner
            setViewCookiesBanner={setViewCookiesBanner}
            openCookiesModal={openCookiesModal}
            setOpenCookiesModal={setOpenCookiesModal}
          />
        )}
        {!!openCookiesModal && (
          <CookiesModal
            setOpenCookiesModal={setOpenCookiesModal}
            setViewCookiesBanner={setViewCookiesBanner}
          />
        )}
        {!!viewCroBanner && !isBlackFriday && (
          <CroBanner
            once={once}
            checkObserve={checkObserve}
            onClose={onCLoseCroBanner}
          />
        )}
      </Suspense>
    </Page>
  );
});
