import { useTranslations } from 'next-intl';
import { FC, memo, useMemo } from 'react';
import cls from './creatorsBlock.module.css';
import { PicturesType } from '../../../model/types/types';
import { Creator } from '../Creator/Creator';
import AlexanderGerchik300x300Avif from '@/shared/assets/images/Desktop/MyCreators/Alexander_Gerchik/Alexander_Gerchik_300x300_Avif.avif';
import AlexanderGerchik300x300Png from '@/shared/assets/images/Desktop/MyCreators/Alexander_Gerchik/Alexander_Gerchik_300x300_Png.png';
import AlexanderGerchik300x300Webp from '@/shared/assets/images/Desktop/MyCreators/Alexander_Gerchik/Alexander_Gerchik_300x300_Webp.webp';
import AntonChumikov300x300Avif from '@/shared/assets/images/Desktop/MyCreators/Anton_Chumikov/Anton_Chumikov_300x300_Avif.avif';
import AntonChumikov300x300Png from '@/shared/assets/images/Desktop/MyCreators/Anton_Chumikov/Anton_Chumikov_300x300_Png.png';
import AntonChumikov300x300Webp from '@/shared/assets/images/Desktop/MyCreators/Anton_Chumikov/Anton_Chumikov_300x300_Webp.webp';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { Flex, GStack, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

interface ICreatorsBlockProps {
  className?: string;
}

export interface ICreator extends PicturesType {
  description: string;
  title: string;
}

export const CreatorsBlock: FC<ICreatorsBlockProps> = memo(props => {
  const { className } = props;
  const t = useTranslations();
  const { isDesktop } = useDetectDevice();

  const creators: ICreator[] = useMemo(
    () => [
      {
        avif: [{ media: 320, src: AntonChumikov300x300Avif }],
        description: t('Anton Chumikov description'),
        png: [{ media: 320, src: AntonChumikov300x300Png }],
        title: t('Anton Chumikov'),
        webp: [{ media: 320, src: AntonChumikov300x300Webp }],
      },
      {
        avif: [{ media: 320, src: AlexanderGerchik300x300Avif }],
        description: t('Alexandr Gerchik description'),
        png: [{ media: 320, src: AlexanderGerchik300x300Png }],
        title: t('Alexandr Gerchik'),
        webp: [{ media: 320, src: AlexanderGerchik300x300Webp }],
      },
    ],
    [t],
  );

  return (
    <Flex
      max
      direction={isDesktop ? 'row' : 'column'}
      justify='between'
      align={isDesktop ? 'start' : 'center'}
      className={classNames(cls['creators-block'], {}, [className])}
    >
      <VStack gap='2-x-l' className={cls.text}>
        <Text as='h2' variant='h2'>
          {t("Jerold' creators")}
        </Text>
        <Text variant='body-l'>{t('creators description')}</Text>
      </VStack>
      <GStack className={cls.wrapper}>
        {creators.map(creator => (
          <Creator key={creator.title} creator={creator} />
        ))}
      </GStack>
    </Flex>
  );
});
