import { FeatureFlags } from '@/shared/types/featureFlags';

const defaultFeatureFlags: FeatureFlags = {
  isBlackFriday: process.env.NEXT_PUBLIC_BLACK_FRIDAY === 'enabled',
  isCart:
    process.env.NEXT_PUBLIC_APP_ENV === 'local' ||
    process.env.NEXT_PUBLIC_APP_ENV === 'dev',
  isCopytrading: process.env.NEXT_PUBLIC_COPYTRADING === 'enabled',
  isDev:
    process.env.NEXT_PUBLIC_APP_ENV === 'local' ||
    process.env.NEXT_PUBLIC_APP_ENV === 'dev',
  isPartnership:
    process.env.NEXT_PUBLIC_APP_ENV === 'local' ||
    process.env.NEXT_PUBLIC_APP_ENV === 'dev' ||
    process.env.NEXT_PUBLIC_APP_ENV === 'stage',
};

let featureFlags: FeatureFlags = {
  ...defaultFeatureFlags,
};

export const setFeatureFlags = (newFeatureFlags?: FeatureFlags) => {
  if (newFeatureFlags) featureFlags = newFeatureFlags;
};

export const getFeatureFlags = (flag: keyof FeatureFlags) =>
  featureFlags?.[flag];

export const getAllFeatureFlags = () => featureFlags;
