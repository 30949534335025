import { useLocale, useTranslations } from 'next-intl';
import { FC, Fragment, memo, useMemo } from 'react';
import cls from './rightSection.module.css';
import { classNames } from '@/shared/lib/classNames/classNames';
import { HStack, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

interface IRightSectionProps {
  className?: string;
}

export const RightSection: FC<IRightSectionProps> = memo(props => {
  const { className } = props;
  const t = useTranslations();
  const language = useLocale();

  const textItems = useMemo(
    () => [
      {
        subtitle: t('Trade from a key levels'),
        title: t('Stop loosing time analyzing charts'),
      },
      {
        subtitle: t(
          'The Screener provides everything you need to find the entry point',
        ),
        title: t('Direction and key level'),
      },
      {
        subtitle: t(
          'Entry points are based on levels where investment funds are building their positions',
        ),
        title: t('Trade like the big players'),
      },
      {
        subtitle: t('With my signals, stable profit is inevitable'),
        title: t('Stable profit along the way'),
      },
    ],
    [t],
  );

  return (
    <HStack
      gap='x-l'
      align='start'
      className={classNames(cls['right-section'], {}, [className])}
    >
      <VStack gap={'x-l'} align='end'>
        {textItems.map(({ title }, index) =>
          index < textItems.length - 1 ? (
            <Fragment key={title}>
              <div className={cls.minus1} />
              <div className={cls.minus2} />
              <div className={cls.minus2} />
              <div className={cls.minus3} />
              <div className={cls.minus2} />
              <div className={cls.minus2} />
            </Fragment>
          ) : (
            <div key={title} className={cls.minus1} />
          ),
        )}
      </VStack>
      <VStack gap='2-x-l' className={cls['text-block']}>
        {textItems.map(({ subtitle, title }) => (
          <VStack key={title} gap='s'>
            <Text as='h3' variant='h3'>
              {title}
            </Text>
            <Text variant='body-m'>{subtitle}</Text>
          </VStack>
        ))}
      </VStack>
    </HStack>
  );
});
