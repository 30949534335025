import { Splide, SplideSlide } from '@splidejs/react-splide';
import { FC } from 'react';
import { packagesBlockSliderOptions } from './packagesBlockSliderOptions';
import { Slide } from '../Slide/Slide';
import { Package, TradeInstruments } from '@/entities/Package';
import { VStack } from '@/shared/ui/Stack';
import './splide.css';
import { Text } from '@/shared/ui/Text';

interface IPackagesSlide {
  item: TradeInstruments[][];
  selectedPackageLocal?: Package;
}

export const PackagesSlide: FC<IPackagesSlide> = props => {
  const { item, selectedPackageLocal } = props;

  return (
    <VStack
      max
      justify='start'
      align='start'
      className='packages-slide-wrapper'
    >
      <Text as='h3' variant='h3'>
        {!!selectedPackageLocal?.name && selectedPackageLocal?.name}
      </Text>
      <Splide options={packagesBlockSliderOptions}>
        {item.map((partArray, index) => (
          <SplideSlide key={index}>
            <Slide partArray={partArray} />
          </SplideSlide>
        ))}
      </Splide>
    </VStack>
  );
};
