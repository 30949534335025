import { useTranslations } from 'next-intl';
import { FC, memo, useRef } from 'react';
import cls from './finishBlock.module.css';
import SphereVideo from '@/shared/assets/videos/sphere/Jerold_animation_1080x1080.mp4';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { Button } from '@/shared/ui/Buttons';
import { Flex, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';
import { Video } from '@/shared/ui/Video/Video';
import { Footer } from '@/widgets/Footer';

interface IFinishBlockProps {
  className?: string;
}

export const FinishBlock: FC<IFinishBlockProps> = memo(props => {
  const { className } = props;
  const t = useTranslations();
  const blockRef = useRef<null | HTMLDivElement>(null);
  const { isDesktop } = useDetectDevice();

  const handleClickScroll = () => {
    const block = document.getElementById('pricingBlock');
    block?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <VStack
      ref={blockRef}
      max
      justify='end'
      className={classNames(cls['finish-block'], {}, [className])}
    >
      <Flex
        direction={isDesktop ? 'row' : 'column'}
        justify='center'
        className={cls['main-part']}
      >
        <Text as='h1' variant='h1'>
          {'Jerold'}
        </Text>
        {!!isDesktop && <Video width={547} height={520} src={SphereVideo} />}
        <Button
          typeButton='accent'
          text={t('Subscribe')}
          onClick={handleClickScroll}
        />
      </Flex>
      <VStack max align='center' className={cls.text}>
        <Text variant='body-m'>{t('footer text')}</Text>
      </VStack>
      <Footer />
    </VStack>
  );
});
