import { FC, memo } from 'react';
import cls from './creator.module.css';
import { ICreator } from '../ui/CreatorsBlock';
import { classNames } from '@/shared/lib/classNames/classNames';
import { Pictures } from '@/shared/ui/Pictures';
import { VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

interface ICreatorsProps {
  className?: string;
  creator: ICreator;
}

export const Creator: FC<ICreatorsProps> = memo(props => {
  const { className, creator } = props;

  return (
    <VStack className={classNames(cls.creator, {}, [className])}>
      <Pictures
        srcAvif={creator.avif}
        srcWebp={creator.webp}
        srcImage={creator.png}
        alt={creator.title}
      />
      <Text as='h3' variant='h3'>
        {creator.title}
      </Text>
      <Text variant='body-m'>{creator.description}</Text>
    </VStack>
  );
});
