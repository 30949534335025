import { useTranslations } from 'next-intl';
import { FC, memo } from 'react';
import cls from './imageSide.module.css';
import { screenerBlockImages } from '../../../../model/consts/screenerBlock';
import { classNames } from '@/shared/lib/classNames/classNames';
import { Pictures } from '@/shared/ui/Pictures';
import { VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

interface IImageSide {
  className?: string;
}

export const ImageSide: FC<IImageSide> = memo(props => {
  const { className } = props;
  const t = useTranslations();

  const images = screenerBlockImages();

  return (
    <VStack
      max
      align='start'
      className={classNames(cls['image-side'], {}, [className])}
    >
      <div className={cls['background-light']} />
      <VStack className={cls.text}>
        <Text variant='body-m-medium'>{'Ticker'}</Text>
        <Text variant='body-s'>{t('Symbol, also known as ticker')}</Text>
      </VStack>
      {images.map(img => (
        <Pictures
          key={img.alt}
          className={cls.picture}
          srcAvif={img.avif}
          srcWebp={img.webp}
          srcImage={img.png}
          alt={img.alt}
        />
      ))}
    </VStack>
  );
});
