import { useTranslations } from 'next-intl';
import { FC, memo } from 'react';
import cls from './leftSection.module.css';
import { classNames } from '@/shared/lib/classNames/classNames';
import { Button } from '@/shared/ui/Buttons';
import { VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

interface ILeftSectionProps {
  className?: string;
}

export const LeftSection: FC<ILeftSectionProps> = memo(props => {
  const { className } = props;
  const t = useTranslations();

  const handleClickScroll = () => {
    const block = document.getElementById('pricingBlock');
    block?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <VStack className={classNames(cls['left-section'], {}, [className])}>
      <Text as='h2' variant='h2'>
        {t('Why Jerold')}
      </Text>
      <Text variant='body-l'>
        {t('Jerold is an assistant that will take your trading')}
      </Text>
      <Button
        text={t('Subscribe')}
        typeButton='accent'
        onClick={handleClickScroll}
      />
    </VStack>
  );
});
