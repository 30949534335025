import { FC, memo, useMemo } from 'react';
import cls from './screenerBlockSkeleton.module.css';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { Skeleton } from '@/shared/ui/Skeleton/Skeleton';
import { Flex, HStack, VStack } from '@/shared/ui/Stack';

interface IScreenerBlockSkeletonProps {
  className?: string;
}

export const ScreenerBlockSkeleton: FC<IScreenerBlockSkeletonProps> = memo(
  props => {
    const { className } = props;
    const { isMobile } = useDetectDevice();

    const directionMain = useMemo(
      () => (isMobile ? 'column' : 'row'),
      [isMobile],
    );
    const justify = useMemo(() => (isMobile ? 'center' : 'start'), [isMobile]);
    const sizeTitleSkeleton = useMemo(() => (isMobile ? 28 : 32), [isMobile]);
    const sizeDescriptionFirstSkeleton = useMemo(
      () => ({ border: '4px', height: 24, width: isMobile ? '100%' : 260 }),
      [isMobile],
    );
    const sizeDescriptionSecondSkeleton = useMemo(
      () => ({ border: '4px', height: 24, width: isMobile ? '100%' : 260 }),
      [isMobile],
    );
    const sizeDescriptionThirdSkeleton = {
      border: '8px',
      height: 24,
      width: '100%',
    };
    const tabsProperty = useMemo(
      () => ({ height: 24, width: isMobile ? '100%' : 80 }),
      [isMobile],
    );
    const contentProperty = useMemo(
      () => ({ border: '4px', width: isMobile ? 60 : 80 }),
      [isMobile],
    );
    const firstContentHeight = 22;
    const secondContentHeight = useMemo(() => (isMobile ? 32 : 40), [isMobile]);

    return (
      <Flex
        max
        direction={directionMain}
        gap='2-x-l'
        align='start'
        className={classNames(cls['screener-block-skeleton'], {}, [className])}
      >
        <VStack max gap='x-l'>
          <HStack
            max
            className={cls['details-title']}
            gap='s'
            justify={justify}
          >
            <Skeleton
              width={sizeTitleSkeleton}
              height={sizeTitleSkeleton}
              border='4px'
            />
            <Skeleton width={140} height={isMobile ? 24 : 32} border='4px' />
          </HStack>
          <VStack max gap='m'>
            <HStack max gap='x-s' align='start'>
              <Skeleton
                {...sizeDescriptionFirstSkeleton}
                className={cls['padding-top']}
              />
              <VStack max gap='x-x-s'>
                <Skeleton {...sizeDescriptionSecondSkeleton} />
                <Skeleton {...sizeDescriptionThirdSkeleton} />
              </VStack>
            </HStack>
            <HStack max gap='x-s' align='start'>
              <Skeleton
                {...sizeDescriptionFirstSkeleton}
                className={cls['padding-top']}
              />
              <VStack max gap='x-x-s'>
                <Skeleton {...sizeDescriptionSecondSkeleton} />
                <Skeleton {...sizeDescriptionThirdSkeleton} />
              </VStack>
            </HStack>
          </VStack>
        </VStack>
        <VStack max className={cls['price-block']} justify='between'>
          <HStack max className={cls.tabs} gap='x-x-s'>
            <HStack max justify='center'>
              <Skeleton {...tabsProperty} />
            </HStack>
            <HStack max justify='center'>
              <Skeleton {...tabsProperty} />
            </HStack>
            <HStack max justify='center'>
              <Skeleton {...tabsProperty} />
            </HStack>
          </HStack>
          <VStack max className={cls.content} justify='between' gap='2-x-l'>
            <HStack max justify='between' align='start'>
              <VStack max gap='l'>
                <VStack max gap='x-s'>
                  <Skeleton {...contentProperty} height={firstContentHeight} />
                  <Skeleton {...contentProperty} height={secondContentHeight} />
                  {!!isMobile && <Skeleton {...sizeDescriptionThirdSkeleton} />}
                </VStack>
                <VStack max gap='x-s'>
                  <Skeleton {...contentProperty} height={firstContentHeight} />
                  <Skeleton {...contentProperty} height={secondContentHeight} />
                  {!!isMobile && (
                    <Skeleton width={60} height={24} border='4px' />
                  )}
                </VStack>
              </VStack>
              {!isMobile && (
                <Skeleton
                  width={162}
                  height={40}
                  border='4px'
                  className={cls['right-block']}
                />
              )}
            </HStack>
            <Skeleton width='100%' height={isMobile ? 48 : 50} border='24px' />
          </VStack>
        </VStack>
      </Flex>
    );
  },
);
