import { FC, memo } from 'react';
import { Package } from '@/entities/Package';
import { Tabs } from '@/shared/ui/Tabs';

interface ITabsPackagesProps {
  className?: string;
  setSelectPackage: (value: Package) => void;
  allPackages: Package[];
}

export const TabsPackages: FC<ITabsPackagesProps> = memo(props => {
  const { className, setSelectPackage, allPackages } = props;

  return (
    allPackages &&
    allPackages.length > 0 && (
      <Tabs
        items={allPackages}
        className={className}
        onChangeItem={setSelectPackage}
      />
    )
  );
});
