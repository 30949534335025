import { Dispatch, SetStateAction } from 'react';
import cls from '../styles/style.module.css';

export const showCurrentBlock = (
  blockId: string,
  value: boolean,
  setOnVisible?: Dispatch<SetStateAction<boolean>>,
) => {
  const elem = document.getElementById(blockId);
  setOnVisible?.(value);
  if (elem)
    value
      ? elem.classList.add(cls['menu-button-link'])
      : elem.classList.remove(cls['menu-button-link']);
};
