import { FC, ReactNode } from 'react';
import cls from './textLink.module.css';
import { classNames } from '../../lib/classNames/classNames';
import { HStack } from '../Stack';
import { TTagName } from '../Text/model/types';
import { SizeElement } from '@/shared/types/ui';

export type TextLinkVariant = 'primary' | 'accent';

interface TextLinksProps {
  activeClassName?: string;
  className?: string;
  variant?: TextLinkVariant;
  size?: SizeElement;
  as?: TTagName;
  wrap?: 'wrap' | 'nowrap';
  children?: ReactNode;
}

export const TextLink: FC<TextLinksProps> = props => {
  const {
    activeClassName = '',
    variant = 'primary',
    className,
    children,
    as = 'p',
    wrap = 'wrap',
    size = 'large',
  } = props;

  const TagNameTextLink = as;

  return (
    <HStack
      className={classNames(cls['app-link'], {}, [
        className,
        cls[size],
        cls[variant],
      ])}
    >
      <TagNameTextLink
        className={classNames('', { [cls.nowrap]: wrap === 'nowrap' }, [])}
      >
        {children}
      </TagNameTextLink>
    </HStack>
  );
};
